import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "services/auth";
import ls from "local-storage";
import AppContext from "contexts/AppContext";
import api from "services/api";
import { Badge, Button } from "react-bootstrap";
import { __ } from "services/Translator";

const CategoryGameSlider = (props) => {
  // const menuOpen = props.menuOpen;
  // const __ = (text) => text;
  const [categoryName, setCategoryName] = useState(props.categoryName);

  return (
    <>
      <section className="category_games">
        <div className="container">
          {!!categoryName && (
            <div className="category_games_header">
              <div className="headline_left">
                <h4 className="game_headline">{categoryName}</h4>
              </div>
            </div>
          )}
          <div className="custom_slider">
            <a href={props.staticBanners[0]?.link} className="single_slide">
              <img className="label_img" src={require("assets/images/slider/label.png").default} />
              {props.staticBanners != "" && <img className="slide_bg_image" src={props.staticBanners[0]?.image} />}
              <div className="slide_text_section">
                <div className="heading_text">
                  {props.staticBanners[0]?.title?.split(":")[0]}
                  <br />
                  <span>{props.staticBanners[0]?.title?.split(":")[1]}.</span>
                </div>
                <div className="info_text">{props.staticBanners[0]?.description}</div>
              </div>
            </a>
            <a href={props.staticBanners[1]?.link} className="single_slide">
              <img className="label_img" src={require("assets/images/slider/label.png").default} />
              {props.staticBanners != "" && <img className="slide_bg_image" src={props.staticBanners[1]?.image} />}

              <div className="slide_text_section">
                <div className="heading_text">
                  {props.staticBanners[1]?.title?.split(":")[0]}
                  <br />
                  <span>{props.staticBanners[1]?.title?.split(":")[1]}.</span>
                </div>
                <div className="info_text">{props.staticBanners[1]?.description}</div>
              </div>
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default CategoryGameSlider;
