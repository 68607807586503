import { Accordion } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SelectBox from "elements/SelectBox";
import InputBox from "elements/InputBox";
import Pagination from "elements/Pagination";
import DateSelector from "elements/DateSelector";
import NoRecordsFound from "elements/NoRecordsFound";
import api from "services/api";
import { __ } from "services/Translator";

export default function FreeSpins(props) {
  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    props.setSpinner(true);
    var response = await api.post("/list/free-spins", form);
    props.setSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  return (
    <div className="dashboard_content history_area_full">
      <div className="right_area table_area p-3">
        <div className="left_area">
          <h3 className="tabName">{__("Free Spins")}</h3>
        </div>
        <div className="d-none d-sm-block">
          <div className="heading_row game_history">
            <div className="item">{__("Title")}</div>
            <div className="item">{__("Expiry")}</div>
            <div className="item">{__("Remaining Spins")}</div>
            <div className="item">{__("Total Spins")}</div>
            <div className="item">{__("Games")}</div>
          </div>
          {results.length ? (
            <>
              {results.map((item, index) => (
                <div key={index} className="data_row">
                  <div className="item">
                    <span className="action_icon">
                      <i className="far fa-check"></i>
                    </span>
                    {item.title}
                  </div>
                  <div className="item">{item.expiry_date}</div>
                  <div className="item">
                    <b>{item.free_spins}</b>
                  </div>
                  <div className="item">
                    <b>{item.original_free_spins}</b>
                  </div>
                  <div className="item">
                    <span className="radius_5">
                      {item.games.length ? (
                        <>
                          {item.games.map((game, i) => (
                            <div key={i}>
                              <Link className="white" to={"/play/" + game.slug}>
                                {game.game_name}
                              </Link>
                            </div>
                          ))}
                        </>
                      ) : (
                        <strong>{__("Any supported game")}</strong>
                      )}
                    </span>
                  </div>
                </div>
              ))}

              <div className="pagination">
                <Pagination data={pagination} onPageChange={onPageChange} />
              </div>
            </>
          ) : (
            <NoRecordsFound />
          )}
        </div>

        <div className="mobile_accordion d-block d-sm-none">
          {results.length ? (
            <>
              <Accordion>
                {results.map((item, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body>
                      <div className="accordion_content">
                        <div className="item">
                          <div className="input_label">{__("Game")}</div>
                          <div className="input_box">
                            {item.games.length ? (
                              <>
                                {item.games.map((game, i) => (
                                  <div key={i}>
                                    <Link className="white" to={"/play/" + game.slug}>
                                      {game.game_name}
                                    </Link>
                                  </div>
                                ))}
                              </>
                            ) : (
                              <strong>{__("Any supported game")}</strong>
                            )}
                          </div>
                        </div>
                        <div className="item">
                          <div className="input_label">{__("Free Spins")}</div>
                          <div className="input_box bg_grey">
                            {item.free_spins}/{item.original_free_spins}
                          </div>
                        </div>
                        <div className="item">
                          <div className="input_label">{__("Expiry")}</div>
                          <div className="input_box bg_grey">{item.expiry_date}</div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>

              <br />
              <br />
              <div className="pagination">
                <Pagination data={pagination} onPageChange={onPageChange} />
              </div>
            </>
          ) : (
            <NoRecordsFound />
          )}
        </div>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
