import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "services/auth";
import GameItem from "elements/games_elements/GameItem";
import ls from "local-storage";
import AppContext from "contexts/AppContext";
import api from "services/api";
import { Button } from "react-bootstrap";
import Flickity from "react-flickity-component";
import Slider from "react-slick";
import { __ } from "services/Translator";

const GameSuggestion = (props) => {
  // const menuOpen = props.menuOpen;

  const flkty = useRef(null);

  const nextLoad = () => {
    // flkty.current.next();
    sliderRef.current.slickNext();
  };

  const prevLoad = () => {
    // flkty.current.previous();
    sliderRef.current.slickPrev();
  };

  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [categoryName, setCategoryName] = useState(props.categoryName);
  const [gameListsData, setGameListsData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(18);
  const [gameType, setGameType] = useState("");

  const getGames = async () => {
    var response = await api.post("/games/by-category", {
      per_page: 6,
      page: page,
      game_types: [gameType],
      // specific_games: props.specificGames,
    });
    setGameListsData([]);
    if (response.status === 200 && response.data) {
      // setPaginationData(response.data.paginationData);
      // setPageActivate(page);

      if (gameType == "popular") {
        setGameListsData(response.data.games.popular.gamelist);
      } else if (gameType == "table_games") {
        setGameListsData(response.data.games.table_games.gamelist);
      } else if (gameType == "video_slots") {
        setGameListsData(response.data.games.video_slots.gamelist);
      } else if (gameType == "video_poker") {
        setGameListsData(response.data.games.video_poker.gamelist);
      } else if (gameType == "live_casino") {
        setGameListsData(response.data.games.live_casino.gamelist);
      } else if (gameType == "jackpot") {
        setGameListsData(response.data.games.jackpot.gamelist);
      }
    }
  };

  useEffect(async () => {
    if (categoryName == "popular") {
      setGameType("popular");
    } else if (categoryName == "Table Games") {
      setGameType("table_games");
    } else if (categoryName == "Slots") {
      setGameType("video_slots");
    } else if (categoryName == "Jackpot Games") {
      setGameType("jackpot");
    } else if (categoryName == "Poker Games") {
      setGameType("video_poker");
    } else if (categoryName == "Live Casino") {
      setGameType("live_casino");
    }
    setPage(1);
  }, [categoryName]);

  useEffect(async () => {
    if (gameType) {
      getGames();
    }
  }, [gameType]);

  const sliderRef = useRef();

  return (
    <>
      <br />
      <h4 style={{ color: "#fff", marginBottom: "none" }}>
        {__("Suggested games")}
      </h4>

      <section className="category_games gameSuggestions">
        {gameListsData?.map((game, index) => (
          <GameItem
            game={game}
            isAuthenticated={props.isAuthenticated}
            key={index}
            {...props}
          />
        ))}
      </section>
    </>
  );
};

export default GameSuggestion;
