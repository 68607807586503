import { Accordion } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SelectBox from "elements/SelectBox";
import InputBox from "elements/InputBox";
import Pagination from "elements/Pagination";
import DateSelector from "elements/DateSelector";
import NoRecordsFound from "elements/NoRecordsFound";
import GameImage from "elements/games_elements/GameImage";
import api from "services/api";
import { __ } from "services/Translator";

export default function GamingHistory(props) {
  const defaultForm = {
    type: "",
    startDate: null,
    endDate: null,
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    props.setSpinner(true);
    var response = await api.post("/history/gaming", form);
    props.setSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const doReset = () => {
    setForm(defaultForm);
  };

  const [exportModal, setExportModal] = useState(false);
  const toggleExportModal = () => {
    setExportModal(!exportModal);
  };

  const trTypes = [
    { label: __("All"), value: null },
    { label: __("Bet"), value: "debit" },
    { label: __("Win"), value: "credit" },
  ];

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  return (
    <div className="dashboard_content history_area">
      <div className="left_area">
        <h3 className="tabName">{__("Gaming History")}</h3>
        <br />
        <div className="row">
          <div className="col-12">
            <div className="input_label">
              <i className="far fa-folder-open"></i> {__("Transaction For")}
            </div>
            <SelectBox
              className="form-controll input_box select_box"
              value={form.type}
              onChange={(itemValue) => updateForm(itemValue, "type")}
              items={trTypes}
            />
          </div>
        </div>
        <div className="row">
          <div className="input_label">
            <i className="far fa-calendar-alt"></i> {__("Start Day")}
          </div>
          <DateSelector
            className="filter_group"
            value={form.startDate}
            onChange={(itemValue) => updateForm(itemValue, "startDate")}
          />
        </div>
        <div className="row">
          <div className="input_label">
            <i className="far fa-calendar-alt"></i> {__("End Day")}
          </div>
          <DateSelector
            className="filter_group"
            value={form.endDate}
            onChange={(itemValue) => updateForm(itemValue, "endDate")}
          />
        </div>
        <div className="button_group">
          <Link
            to="#"
            onClick={getResults}
            className="common_btn uppercase no_margin"
          >
            {__("Search")}
          </Link>
          <Link
            to="#"
            onClick={doReset}
            className="common_btn uppercase borderd no_margin"
          >
            {__("Reset")}
          </Link>
        </div>
      </div>

      <div className="right_area table_area">
        {/* desktop */}
        <div className="d-none d-sm-block">
          <div className="heading_row game_history">
            <div className="item">{__("Game")}</div>
            <div className="item">{__("Type")}</div>
            <div className="item">{__("Balance Type")}</div>
            <div className="item">{__("Freeround")}</div>
            <div className="item">{__("Amount")}</div>
            <div className="item">{__("Cash Balance Before")}</div>
          </div>
          {results.length ? (
            <>
              {results.map((item, index) => (
                <div key={index} className="data_row game_history">
                  <div className="item">
                    <GameImage
                      className="game_img"
                      src={item.game?.game_snapshot_url}
                    />
                    <br />
                    {item.game?.game_name}
                    <br />
                    {item.record_date}
                  </div>
                  <div className="item">{item.type}</div>
                  <div className="item">{item.using_bonus}</div>
                  <div className="item">{item.is_freeround}</div>
                  <div className="item">
                    <span className="table_btn">{item.amount}</span>
                  </div>
                  <div className="item">
                    <span className="table_btn">{item.balance_before}</span>
                  </div>
                </div>
              ))}

              <div className="pagination">
                <Pagination data={pagination} onPageChange={onPageChange} />
              </div>
            </>
          ) : (
            <NoRecordsFound />
          )}
        </div>
        {/* mobile */}
        <div className="mobile_accordion d-block d-sm-none">
          {results.length ? (
            <>
              <Accordion>
                {results.map((item, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{item.game?.game_name}</Accordion.Header>
                    <Accordion.Body>
                      <div className="accordion_content">
                        <div className="item">
                          <div className="input_label">{__("Game")}</div>
                          <div className="input_box bg_grey">
                            {item.game?.game_name}
                          </div>
                        </div>
                        <div className="item">
                          <div className="input_label">{__("Type")}</div>
                          <div className="input_box bg_grey">{item.type}</div>
                        </div>
                        <div className="item">
                          <div className="input_label">{__("Balance Type")}</div>
                          <div className="input_box bg_grey">
                            {item.using_bonus}
                          </div>
                        </div>
                        <div className="item">
                          <div className="input_label">{__("Freeround")}</div>
                          <div className="input_box bg_grey">
                            {item.is_freeround}
                          </div>
                        </div>
                        <div className="item">
                          <div className="input_label">{__("Amount")}</div>
                          <div className="input_box bg_grey">{item.amount}</div>
                        </div>

                        <div className="item">
                          <div className="input_label">{__("Cash Balance Before")}</div>
                          <div className="input_box bg_grey">
                            {item.balance_before}
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>

              <br />
              <br />
              <div className="pagination">
                <Pagination data={pagination} onPageChange={onPageChange} />
              </div>
            </>
          ) : (
            <NoRecordsFound />
          )}
        </div>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
