import React, { useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import api from "services/api";
import { Modal } from "react-bootstrap";
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import VipLevelText from "elements/VipLevelText";
import SingleCategoryGames from "elements/games_elements/SingleCategoryGames";
import { __ } from "services/Translator";
import { Helmet } from "react-helmet";
import { cauculateLandScape, isIOS } from "../services/isMobile";

export default function GamePlay(props) {
  const [gameList, setGameList] = useState([]);
  const [gameSorting, setGameSorting] = useState("Most Popular");

  const getGameList = async () => {
    props.setSpinner(true);
    var response = await api.post("/games/by-category", {
      per_page: 5,
      game_types: ["popular"],
      page: 1,
      game_sorting: gameSorting,
      providers: props.selectedProviders,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      setGameList(response.data.games?.popular?.gamelist ?? []);
    }
  };

  // const __ = (text) => text;

  useEffect(() => {
    getGameList();
  }, [gameSorting, props.selectedProviders]);

  const params = useParams();
  const history = useHistory();
  const isAuthenticated = props.isAuthenticated;
  const [launchUrl, setLaunchUrl] = useState("");
  const [gameDetails, setGameDetails] = useState("");
  const [gamePlayMode, setGamePlayMode] = useState(params.mode ? params.mode : "real");
  const [gameSessionId, setGameSessionId] = useState(null);

  const toggleFullScreen = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      var element = document.getElementById("game_play_window");
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  };

  const iframeSrcChanged = (event) => {
    // alert(event.target.contentWindow.location);
  };

  const getPlay = async () => {
    props.setSpinner(true);
    var response = await api.post("/game/initsession", {
      slug: params.slug,
      mode: gamePlayMode,
    });
    if (response.status === 200 && response.data) {
      props.setSpinner(true);
      if (isMobileDeviceByViewPort) {
        window.location.href = response.data.launchUrl;
      } else {
        setLaunchUrl(response.data.launchUrl);
        setGameSessionId(response.data.sessionId);
        setGameDetails(response.data.details);
        setTimeout(() => {
          props.setSpinner(false);
        }, 5000);
      }
    } else {
      props.setSpinner(false);
      props.showAlert("Warning!!", response.data.message, true, () => {
        history.push("/play/" + params.slug + "/demo");
      });
    }
  };

  useEffect(() => {
    getPlay();
  }, [gamePlayMode, params.slug]);

  const [isFavorite, setIsFavorite] = useState(gameDetails.is_favorite_game);
  const toggleFavoriteGame = async () => {
    var response = await api.post("/toggle-favorite-game", {
      gameId: gameDetails.id,
    });
    if (response.status === 200) {
      if (response.data.status === "added") {
        setIsFavorite(true);
      } else {
        setIsFavorite(false);
      }
    }
  };

  const [bonusUnsupportedWarningModal, setBonusUnsupportedWarningModal] = useState({ show: false });

  useEffect(() => {
    if (
      gameDetails &&
      props.userBalance &&
      gamePlayMode !== "demo" &&
      !gameDetails.bonus_supported &&
      parseFloat(props.userBalance.cash_balance) === 0.0
    ) {
      setBonusUnsupportedWarningModal({ show: true });
    } else {
      setBonusUnsupportedWarningModal({ show: false });
    }
  }, [gamePlayMode, props.userBalance.cash_balance, gameDetails]);

  const closeAllModals = () => {
    setGameHubMessageModal({ show: false });
    setBonusUnsupportedWarningModal({ show: false });
  };

  //GameHub message functionality
  const [gameHubMessageModal, setGameHubMessageModal] = useState({
    title: "",
    message: "",
    show: false,
  });

  const loadGameHubMessage = async () => {
    var response = await api.post("/load-game-hub_message", {
      provider: gameDetails.game_aggregator,
      game_session_id: gameSessionId,
    });
    if (response.status === 200) {
      if (response.data.type == "found") {
        //There is a message to show.
        var modalData = {
          ...modalData,
          show: true,
          title: __("Warning!!"),
          message: response.data.gamehub_message.message,
          message_type: response.data.gamehub_message.message_type,
        };

        setGameHubMessageModal(modalData);
      } else if (response.data.type == "session_created") {
        //Session type changed. Message to show and game area to reload
        setGameHubMessageModal({
          ...gameHubMessageModal,
          message_type: response.data.gamehub_message.message_type,
          title: __("Balance type changed!"),
          message: response.data.gamehub_message.message,
          show: true,
        });
        setGameSessionId(response.data.newSession.sessionId);
        setLaunchUrl(response.data.newSession.launchUrl);
      }
    }
  };

  useEffect(() => {
    var interval = setInterval(function () {
      if (gamePlayMode !== "demo") {
        loadGameHubMessage();
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [gameSessionId, gameDetails, isAuthenticated, gamePlayMode]);

  //GamePlay Page detection
  const [mobileIframeStyle, setMobileIframeStyle] = useState({});
  const isMobileDevice = props.isMobileDevice;
  const isMobileDeviceByViewPort = props.isMobileDeviceByViewPort;
  const isIOSDevice = isIOS();
  const isLandscape = props.isLandscape;

  const calclateMobileIframeStyle = () => {
    var fixedFooterOffset = 52;
    if (isIOSDevice) {
      fixedFooterOffset = 46;
    }
    var style = {
      width: "100vw",
      height: window.innerHeight - fixedFooterOffset,
    };
    if (cauculateLandScape()) {
      style.height = window.innerHeight;
    }
    setMobileIframeStyle(style);
    scrollToTop();
  };

  const scrollToTop = () => {
    if (isMobileDevice) {
      // alert(44);
      window.scrollTo(0, 1);
    }
  };

  useEffect(() => {
    calclateMobileIframeStyle();
    window.addEventListener("resize", calclateMobileIframeStyle);
    window.addEventListener("scroll", scrollToTop);
    return () => {
      window.removeEventListener("resize", calclateMobileIframeStyle);
      window.removeEventListener("scroll", scrollToTop);
    };
  }, []);

  useEffect(() => {
    window.gotoGamesPage = () => {
      history.push("/");
    };
  }, []);

  const [topWinners, setTopWinners] = useState([]);

  const getTopWinners = async () => {
    var response = await api.get("/games/top-winners?limit=5");
    if (response.status === 200) {
      setTopWinners(response.data.data);
    } else {
      console.log(response.data);
    }
  };
  const [detailsTab, setDetailsTab] = useState("Description");
  const [theaterMode, setTheaterMode] = useState(false);
  useEffect(() => {
    getTopWinners();
    // if(isAuthenticated){
    //   setGamePlayMode('real');
    // } else {
    //   setGamePlayMode('demo');
    // }
  }, []);

  const [maximumWagersPerRoundSettings, setMaximumWagersPerRoundSettings] = useState([]);
  const getMaximumWagersPerRoundSettings = async () => {
    var response = await api.post("/maximum-wagers-per-round-settings", {
      type: "Cash",
    });
    if (response.status === 200) {
      setMaximumWagersPerRoundSettings(response.data);
      // console.log('maximumWagersPerRoundSettings', response.data);
    }
  };

  const [maximumBonusWagersPerRoundSettings, setMaximumBonusWagersPerRoundSettings] = useState([]);
  const getMaximumBonusWagersPerRoundSettings = async () => {
    var response = await api.post("/maximum-wagers-per-round-settings", {
      type: "Bonus",
    });
    if (response.status === 200) {
      setMaximumBonusWagersPerRoundSettings(response.data);
      // console.log('maximumWagersPerRoundSettings', response.data);
    }
  };

  useEffect(() => {
    getMaximumWagersPerRoundSettings();
    getMaximumBonusWagersPerRoundSettings();
  }, []);

  return (
    <>
      <Helmet>
        <title>{__("Zino Casino")}</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      {!isMobileDevice && (
        <>
          <div className={"game_play " + (theaterMode ? "theaterModeEnabled" : "")}>
            <div className="row">
              <div className="col-12 col-xl-12">
                <div className={"game_content "}>
                  {/* <img
                className="placeholder"
                src="https://stage.game-program.com/media/images/slots/small/p0/jpg/p0-sweet-bonanza.jpg"
              /> */}
                  <iframe
                    style={{ display: "block !important;" }}
                    id="game_play_window"
                    width="100%"
                    height="100%"
                    src={launchUrl}
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="frame_bottom">
                  <div className="actions_icon">
                    <OverlayTrigger overlay={<Tooltip>Full-Screen</Tooltip>}>
                      <Link>
                        <i onClick={toggleFullScreen} className="fas fa-expand"></i>
                      </Link>
                    </OverlayTrigger>
                    <Link>
                      <OverlayTrigger className="tooltipStyle" overlay={<Tooltip>Theater Mode</Tooltip>}>
                        <i
                          onClick={() => {
                            if (theaterMode) {
                              setTheaterMode(false);
                            } else {
                              setTheaterMode(true);
                            }
                          }}
                          className="fas fa-expand-wide"
                        ></i>
                      </OverlayTrigger>
                    </Link>
                    {isAuthenticated && (
                      <i onClick={toggleFavoriteGame} className={(isFavorite ? "fas" : "far") + " fa-heart"}></i>
                    )}

                    <select className="currency_select d-none">
                      <option>USD</option>
                      <option>EUR</option>
                    </select>
                  </div>
                  <div className="text_area white">
                    {gameDetails.game_name} | {gameDetails.provider?.name ?? ""}
                  </div>

                  <div className="actions_btn">
                    <div className="custom-control custom-switch d-none">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customSwitches"
                        defaultChecked={gamePlayMode == "real" ? true : false}
                        onChange={(ev) => {
                          var target = ev.target;
                          if (target.checked) {
                            setGamePlayMode("real");
                          } else {
                            setGamePlayMode("demo");
                          }
                        }}
                      />
                      <label className="custom-control-label" for="customSwitches">
                        {__("Real Play")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />

            <div className="row d-none">
              <div className="col-12">
                <div className="game_badge">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="header_flex">
                          <div className="text_area">
                            {__("Joker Bombs")} <span>{__("Hacksaw Gaming")}</span>
                          </div>
                          <div className="icon_area">
                            <i className="fa fa-trophy golden"></i>
                            <span className="">1234</span>
                            <i className="fa fa-times"></i>
                            <span className="">{__("Hidden")}</span>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="tabs">
                          <li
                            onClick={() => {
                              setDetailsTab("Big Wins");
                            }}
                            className={detailsTab === "Big Wins" ? "active" : ""}
                          >
                            {__("Big Wins")}
                          </li>
                          <li
                            onClick={() => {
                              setDetailsTab("Lucky Wins");
                            }}
                            className={detailsTab === "Lucky Wins" ? "active" : ""}
                          >
                            {__("Lucky Wins")}
                          </li>
                          <li
                            onClick={() => {
                              setDetailsTab("Challenges");
                            }}
                            className={detailsTab === "Challenges" ? "active" : ""}
                          >
                            {__("Challenges")}
                          </li>
                          <li
                            onClick={() => {
                              setDetailsTab("Description");
                            }}
                            className={detailsTab === "Description" ? "active" : ""}
                          >
                            {__("Description")}
                          </li>
                        </div>
                        <br />

                        {detailsTab === "Big Wins" && (
                          <h3 className="text-center paragraph">{__("No Data available")}</h3>
                        )}
                        {detailsTab === "Lucky Wins" && (
                          <h3 className="text-center paragraph">{__("No Data available")}</h3>
                        )}
                        {detailsTab === "Challenges" && (
                          <h3 className="text-center paragraph">{__("No Data available")}</h3>
                        )}
                        {detailsTab === "Description" && (
                          <div className="description">
                            <div className="game_img">
                              <img src={require("../assets/images/games/games1.png").default} />
                            </div>
                            <div className="details">
                              <h3 className="text-center paragraph">{__("No Description")}</h3>
                              <br />
                              <div className="lavels">
                                <li>
                                  {__("Edge")}: <strong>3.52 %</strong>
                                </li>
                              </div>
                              <br />
                              <br />
                              <div className="clearfix"></div>
                              <div className="lavels">
                                <li>{__("hello")}</li>
                                <li>{__("bubbles")}</li>
                                <li>{__("Candy")}</li>
                                <li>{__("Feature Buy-in")}</li>
                                <li>{__("Featured Slots")}</li>
                                <li>{__("Hacksaw Gaming")}</li>
                                <li>{__("Joker")}</li>
                                <li>{__("Level Up")}</li>
                                <li>{__("Party")}</li>
                              </div>
                            </div>
                            <div className="clearfix"></div>
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
            <br />
            <div div className="row d-none">
              <div className="col-12 col-xl-4">
                <div className="game_details">
                  <div>
                    <div className="headiing">{__("Game Details")}:</div>
                    <div className="single_details_block">
                      <div className="item">
                        <div className="item_name">{__("Mode")}</div>
                        <div className="item_value"> {gamePlayMode === "demo" ? "Fun Play" : "Real Play"}</div>
                      </div>
                      <div className="item active">
                        <div className="item_name">{__("Currently Playing")}:</div>
                        <div className="item_value">{gameDetails.game_name}</div>
                      </div>
                    </div>
                    {/* <div className="single_details_block">
                  <div className="item">
                    <div className="item_name">Other info:</div>
                    <div className="item_value">Lorem ipsum</div>
                  </div>
                  <div className="item">
                    <div className="item_name">Other info:</div>
                    <div className="item_value">Lorem ipsum</div>
                  </div>
                </div> */}
                    {/* <div className="single_details_block">
                  <div className="item">
                    <div className="item_name">Other info:</div>
                    <div className="item_value">Lorem ipsum</div>
                  </div>
                  <div className="item">
                    <div className="item_name">Other info:</div>
                    <div className="item_value">Lorem ipsum</div>
                  </div>
                </div> */}
                  </div>
                  <div>
                    <div className="headiing">{__("Top Players")}</div>
                    {topWinners.map((item, index) => (
                      <div className="single_details_block players">
                        <div className="item">
                          <div className="game_block">
                            <div className="game_thumb">
                              <img src={item.game.game_snapshot_url} />
                            </div>
                            <div className="game_name">
                              <div className="d-flex">
                                {item.player.player_name + " " + item.player.player_surname}
                                <span className="level">{item.player.identity}</span>
                                <sup className="supper">Lvl</sup>
                              </div>
                              <div className="item_name">
                                <VipLevelText {...item.player} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          {/* <CategoryGames
        {...props}
        gameList={gameList}
        categoryName="Popular Games"
        category="popular"
        setGameSorting={setGameSorting}
        gameSorting={gameSorting}
      /> */}

          <SingleCategoryGames
            categoryName="Popular"
            category="popular"
            perPage="4"
            isAuthenticated={isAuthenticated}
          />
        </>
      )}

      {/* {isMobileDevice && <iframe onLoad={iframeSrcChanged} src={launchUrl} style={mobileIframeStyle} />} */}
      {isMobileDeviceByViewPort && (
        <iframe className="mobile_game_frame" style={mobileIframeStyle} onLoad={iframeSrcChanged} src={launchUrl} />
      )}

      <Modal className="gamePlayMsgModal" show={gameHubMessageModal.show} onHide={closeAllModals}>
        {/* <Modal className="gamePlayMsgModal" show={1} onHide={closeAllModals}> */}
        <Modal.Header>
          <h5 className="modal-title" id="exampleModalLabel">
            {gameHubMessageModal.message_type == "maximum_cash_wagers_per_round"
              ? // __("Maximum cash wager limit exceeded!")
                __("Uh oh...")
              : gameHubMessageModal.message_type == "maximum_bonus_wagers_per_round"
              ? __("Maximum bonus wagers limit exceeded!")
              : gameHubMessageModal.message_type == "bonus_balance_not_support"
              ? __("Insufficient balance!")
              : gameHubMessageModal.message_type == "maximum_wagers"
              ? __("Maximum cash wager limit exceeded!")
              : gameHubMessageModal.message_type == "maximum_loss"
              ? __("Maximum cash loss limit exceeded!")
              : gameHubMessageModal.message_type == "maximum_playing_time"
              ? __("Maximum play time limit exceeded!")
              : gameHubMessageModal.title}
          </h5>
          {/* <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeAllModals}
          >
            <span aria-hidden="true">&times;</span>
          </button> */}
        </Modal.Header>
        <Modal.Body className="login_modal forgot_pass">
          <div className="game_message_content">
            <i className="fas fa-exclamation-triangle"></i>
            <br />
            {gameHubMessageModal.message_type == "maximum_cash_wagers_per_round" ? (
              <>
                {/* You have exceeded the maximum bet amount for cash money. Please
                lower the amount and try again. See our{" "}
                <Link to="">General Terms & Conditions</Link> for more
                information. */}
                {__("Opening month betting limits apply")}
                <br />
                {__("Max Bet")} $
                {gameDetails.type == "video-slot"
                  ? maximumWagersPerRoundSettings != ""
                    ? maximumWagersPerRoundSettings.slots.limit_amount
                    : ""
                  : gameDetails.type == "Live Casino"
                  ? maximumWagersPerRoundSettings != ""
                    ? maximumWagersPerRoundSettings.live_casinos.limit_amount
                    : ""
                  : gameDetails.type == "livecasino"
                  ? maximumWagersPerRoundSettings != ""
                    ? maximumWagersPerRoundSettings.live_casinos.limit_amount
                    : ""
                  : maximumWagersPerRoundSettings != ""
                  ? maximumWagersPerRoundSettings.others.limit_amount
                  : ""}
                <br />
                <br />
                <span style={{ fontSize: "14px" }}>{__("Unlimited bets from May 19")}</span>
              </>
            ) : gameHubMessageModal.message_type == "maximum_bonus_wagers_per_round" ? (
              <>
                {/* You have exceeded the maximum bet amount for bonus money. Please
                lower the amount and try again. See our{" "} */}
                {__("Opening month betting limits apply")}
                <br />
                {__("Max Bet")} $
                {gameDetails.type == "video-slot"
                  ? maximumBonusWagersPerRoundSettings != ""
                    ? maximumBonusWagersPerRoundSettings.slots.limit_amount
                    : ""
                  : gameDetails.type == "Live Casino"
                  ? maximumBonusWagersPerRoundSettings != ""
                    ? maximumBonusWagersPerRoundSettings.live_casinos.limit_amount
                    : ""
                  : gameDetails.type == "livecasino"
                  ? maximumBonusWagersPerRoundSettings != ""
                    ? maximumBonusWagersPerRoundSettings.live_casinos.limit_amount
                    : ""
                  : maximumBonusWagersPerRoundSettings != ""
                  ? maximumBonusWagersPerRoundSettings.others.limit_amount
                  : ""}
                <Link to="">{__("Bonus Terms")}</Link> {__("for more information")}.
              </>
            ) : gameHubMessageModal.message_type == "bonus_balance_not_support" ? (
              <>
                {__(
                  "Bonus play is not supported for this game. You do not have sufficient cash balance to play this game."
                )}
                {__("See our")} <Link to="">{__("Bonus Terms")}</Link> {__("for more information")}. {__("Note")}:{" "}
                {__("For Live Casino games and Sports Betting, only cash wagers are accepted")} ({__("no bonus wagers")}
                ).
              </>
            ) : (
              <>{gameHubMessageModal.message}</>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="common_btn" onClick={closeAllModals}>
            OK
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={bonusUnsupportedWarningModal.show} onHide={closeAllModals}>
        <Modal.Header>
          <h5 className="modal-title" id="exampleModalLabel">
            {__("Game playing with bonus money!")}
          </h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeAllModals}>
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="login_modal forgot_pass">
          <div className="game_message_content">
            {__("This game is not available for bonus money. Only cash money is accepted.")}
            <br />
            {__("Note: All Live Casino games are not available for bonus money wagering.")}
            <br />
            <Link to="/bonus">{__("Click here")}</Link>{" "}
            {__("to see which games are available for Bonus Money playing.")}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
