import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link, useLocation } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import config from "configs/config";
import api from "services/api";
import moneyFormatter from "services/moneyFormatter";
import { isMobile, cauculateLandScape } from "services/isMobile";
import VipLevelText, { NextVipLevelText } from "elements/VipLevelText";
import { __ } from "services/Translator";
import ls from "services/ls";

const RightSidebar = (props) => {
  const { updateUserObj } = React.useContext(AppContext);
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const userBalance = props.userBalance;
  const userDetails = props.userDetails;
  const accountProgress = props.accountProgress;
  const location = useLocation();

  const logout = async () => {
    await auth.logout();
    await updateUserObj();
    setIsAuthenticated(auth.isAuthenticated);
  };

  const [collapsesOpen, setCollapsesOpen] = useState({
    progress: false,
    userMenu: false,
  });

  const toggleCollapse = (ev) => {
    var id = ev.target.dataset.collapse;
    setCollapsesOpen({ ...collapsesOpen, [id]: !collapsesOpen[id] });
  };

  const hideRightSidebar = (ev) => {
    if (props.rightSidebarOpen) {
      if (ev) {
        if (ev.type === "click" && ev.target.id === "right_sidebar_overlay") {
          //If clicked outside the right sidebar
          props.toggleRightSidebar();
        }
      } else {
        props.toggleRightSidebar();
      }
    }
  };

  useEffect(() => {
    hideRightSidebar(null);
  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener("click", hideRightSidebar);
    return () => {
      window.removeEventListener("click", hideRightSidebar);
    };
  }, [props.rightSidebarOpen]);

  return isAuthenticated ? (
    <>
      {props.rightSidebarOpen && <div id="right_sidebar_overlay"></div>}
      <nav
        id="right_sidebar"
        className={
          "logged_in_sidebar " + (props.rightSidebarOpen ? "active" : "")
        }
      >
        <div id="right_dismiss" onClick={props.toggleRightSidebar}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </div>
        <div className="side_bar_menu_text">{__("Profile")}</div>
        <ul className="account_menu">
          <li className="single_slide_menu_item">
            {__("Total Balance")} : USD{" "}
            <span className="total_balance">
              {moneyFormatter.balanceFormat(
                userBalance.total_balance,
                (ls.get("fiatCurrencyToDisplay") ? ls.get("fiatCurrencyToDisplay"): 'USD')
              )}
            </span>
            <span className="caret"></span>
          </li>
          <li>
            <span>{__("Cash")}</span>
            <span>
              {" "}
              USD{" "}
              <span className="cash_balance">
                {moneyFormatter.balanceFormat(
                  userBalance.cash_balance,
                  (ls.get("fiatCurrencyToDisplay") ? ls.get("fiatCurrencyToDisplay"): 'USD')
                )}
              </span>{" "}
            </span>
          </li>
          <li>
            <span>{__("Bonuses")}</span>{" "}
            <span>
              USD{" "}
              <span className="bonus_balance">
                {moneyFormatter.balanceFormat(
                  userBalance.bonus_balance,
                  (ls.get("fiatCurrencyToDisplay") ? ls.get("fiatCurrencyToDisplay"): 'USD')
                )}
              </span>{" "}
            </span>
          </li>

          <li className="player_vip_level_info hasDropdownMenu">
            <a
              className="has_menu_action"
              href="#"
              data-collapse="progress"
              onClick={toggleCollapse}
            >
              <i className="fa fa-star"></i>
              {__("VIP Level")} <VipLevelText identity={userDetails.identity} />
              <span className="caret"></span>
              <div className="pbar d-none">
                <div className="progress">
                  <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{ width: accountProgress + "%" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <i className="icon fas fa-angle-down"></i>
            </a>
            <Collapse in={collapsesOpen.progress}>
              <ul>
                <li>
                  {__("Next VIP Level")}:
                  <NextVipLevelText identity={userDetails.identity} />(
                  {parseInt(accountProgress)}%)
                </li>
              </ul>
            </Collapse>
          </li>

          <li className="hasDropdownMenu">
            <a
              className="has_menu_action"
              href="#"
              data-collapse="userMenu"
              onClick={toggleCollapse}
            >
              {userDetails.first_name} {userDetails.last_name}
              <i className="icon fas fa-angle-down"></i>
            </a>
            <Collapse in={collapsesOpen.userMenu}>
              <ul>
                <li>
                  <Link to="/dashboard/account-details">
                    {__("My Account")}
                  </Link>
                </li>
                <li>
                  <Link to="/dashboard/cashier">{__("Deposit")}</Link>
                </li>
                <li>
                  <Link to="/dashboard/cashier">{__("Withdraw")}</Link>
                </li>
                <li>
                  <Link to="/dashboard/my-games">{__("My Games")}</Link>
                </li>
              </ul>
            </Collapse>
          </li>
          <li className="single_slide_menu_item">
            <a onClick={logout} href="#">
              {__("Log Out")}
            </a>
            <i className="icon fas fa-sign-out-alt"></i>
          </li>
        </ul>
      </nav>
    </>
  ) : (
    <></>
  );
};

export default RightSidebar;
