import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { DropdownButton, Dropdown } from "react-bootstrap";

// components

import Terms from "contentPage/Terms";

export default function ContentPage(props) {
  const params = useParams();
  const page = params.page;
  const [activeTab, setActiveTab] = useState("account-details");
  const [activeTabName, setActiveTabName] = useState("Account Details");
  const history = useHistory();

  const closeDetails = () => {
    // history.goBack();
  };

  
  const [travelingToRight, setTravelingToRight] = useState(false);

  useEffect(() => {
    setActiveTab(page);
   

    setTravelingToRight(true);
    setTimeout(function () {
      setTravelingToRight(false);
    }, 500);

    // alert(tab)
  }, [page]);

  return (
    <div className="dashboard_container">
      <div className={"user_dashboard " + (travelingToRight ? 'cmstravelingToRight': '') }>
        {/* <div onClick={closeDetails} className="close_icon">
          <svg
            width="28"
            height="28"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <title>close</title>
            <path d="M18.984 6.422L13.406 12l5.578 5.578-1.406 1.406L12 13.406l-5.578 5.578-1.406-1.406L10.594 12 5.016 6.422l1.406-1.406L12 10.594l5.578-5.578z"></path>
          </svg>
        </div> */}
        <div className="dashboard_page  ">
          {activeTab === "terms-and-conditions" && <Terms {...props} />}
          {activeTab === "confidentiality" && <Terms {...props} />}
        </div>
      </div>
    </div>
  );
}
