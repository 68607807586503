import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { DropdownButton, Dropdown } from "react-bootstrap";
import api from "../services/api";
import auth from "../services/auth";

export default function TermsAndconditions(props) {
  const params = useParams();
  const [pageContent, setPageContent] = useState("");
  const getContentPage = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: "terms_conditions",
    });

    if (response.status == 200) {
      setPageContent(response.data);
    }
  };

  useEffect(async () => {
    getContentPage();
  }, []);
  const renderHTML = (rawHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  const [travelingToRight, setTravelingToRight] = useState(false);

  useEffect(() => {
    setTravelingToRight(true);
    setTimeout(function () {
      setTravelingToRight(false);
    }, 500);

    // alert(tab)
  }, []);

  return (
    <>
      <div className="dashboard_container">
        <div
          className={
            "user_dashboard " + (travelingToRight ? "cmstravelingToRight" : "")
          }
        >
         
          <div className="dashboard_page  ">
            <div className="terms_content">
              <div className="page_title">
                {pageContent && pageContent.content_data.page_title}
              </div>

              <div className="paragraph">
                {pageContent && renderHTML(pageContent.content_data.content)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
