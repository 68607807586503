import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import AppContext from "contexts/AppContext";
import auth from "services/auth";
import { __ } from "services/Translator";
import SearchBox from "elements/SearchBox";
import LanguageSwitcher from "elements/LanguageSwitcher";
import VipLevelText, { NextVipLevelText } from "elements/VipLevelText";

import Clock from "react-live-clock";

export default function PreLeftSidebar(props) {
  const params = useParams();
  const { updateUserObj } = React.useContext(AppContext);
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const tab = params.tab;
  const [showSidebar, setShowSidebar] = useState(props.showPreLeftSidebar);
  const location = useLocation();
  //   alert(props.openLeftSidebar);
  // const showSidebar = props.showLeftSidebar;
  // const setShowSidebar = props.setShowLeftSidebar;

  const logout = async () => {
    await auth.logout();
    await updateUserObj();
    setIsAuthenticated(auth.isAuthenticated);
  };

  useEffect(() => {
    setShowSidebar(props.showPreLeftSidebar);
    // if (props.showPreLeftSidebar) {
    //   setShowSidebar(props.showPreLeftSidebar);
    // }
    console.log("props.showPreLeftSidebar", props.showPreLeftSidebar);
  }, [props]);

  const accountProgress = props.accountProgress;

  const hideSidebar = () => {
    setShowSidebar(false);
    props.setShowPreLeftSidebar(false);
  };

  useEffect(() => {
    window.addEventListener("swiped-left", hideSidebar);
    return () => {
      window.removeEventListener("swiped-left", hideSidebar);
    };
  }, []);

  return (
    <>
      <div className="sidebar_container left_sidebar menu_left_bar pre_left_bar ">
        <div className={"sidebar_area menu_left_bar  " + (showSidebar ? "show_bar extendedMenu" : "")}>
          <a className="button_close">
            <i
              onClick={() => {
                setShowSidebar(false);
                props.setShowPreLeftSidebar(false);
              }}
              className="fa fa-times"
              aria-hidden="true"
            ></i>
          </a>

          <div className="left_side">
            <div className="logo">
              <Link
                onClick={() => {
                  if (props.isMobileDevice) {
                    setShowSidebar(false);
                    props.setShowPreLeftSidebar(false);
                  }
                }}
                to="/"
              >
                <img src={require("../assets/images/logos/zino-logo-new.png").default} />
              </Link>
            </div>

            <SearchBox {...props} />
            <div className="pre_menu_top_area">
              <div className="pre_menu_top">
                <div className="user_avatar">
                  <div className="user_avatar_content">
                    {!props.isAuthenticated && (
                      <Link to="/">
                        <img
                          className="avatar_image"
                          src={require("../assets/images/common/user-avatar-white.svg").default}
                        />
                      </Link>
                    )}
                    {props.isAuthenticated && (
                      <>
                        <img
                          className="avatar_image"
                          src={require("../assets/images/common/user-avatar-white.svg").default}
                          onClick={() => {
                            props.setShowLeftSidebar(!props.showLeftSidebar);
                            if (props.isMobileDevice) {
                              setShowSidebar(false);
                              props.setShowPreLeftSidebar(false);
                            }
                          }}
                        />
                        <Link className="userName">{props.userDetails.username}</Link>
                      </>
                    )}
                  </div>
                  {props.isMobileDevice && <LanguageSwitcher {...props} />}
                </div>

                {props.isAuthenticated && (
                  <div className="user_balance_area">
                    <div className="user_balance">
                      {props.userBalance.currency}
                      {props.userBalance.total_balance}
                      <Link to="/dashboard/cashier">
                        <i className="fas fa-plus-circle"></i>
                      </Link>
                    </div>

                    <div className="vip_level_area">
                      <a className="has_menu_action" href="javascript:" data-collapse="progress">
                        <i className="fa fa-star"></i>
                        {__("VIP Level")} <VipLevelText identity={props.userDetails.identity} />
                        <span className="caret"></span>
                        <div className="pbar">
                          <div className="progress">
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{ width: accountProgress + "%" }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                        {/* <i className="icon fas fa-angle-down"></i> */}
                      </a>

                      <ul className="next_vip_level">
                        <li>
                          {__("Next VIP Level")}: <NextVipLevelText identity={props.userDetails.identity} /> (
                          {parseInt(accountProgress)}%)
                        </li>
                      </ul>
                    </div>

                    <ul>
                      <li className="notificationMenu">
                        <Link
                          onClick={() => {
                            if (props.isMobileDevice) {
                              setShowSidebar(false);
                              props.setShowPreLeftSidebar(false);
                            }
                          }}
                          to="/dashboard/notifications"
                        >
                          <div className="icon">
                            <i className="fas fa-bell"></i>
                          </div>
                          <div className="text">{__("Notifications")}</div>
                        </Link>
                      </li>
                      <li className="cashierMenu">
                        <Link
                          onClick={() => {
                            if (props.isMobileDevice) {
                              setShowSidebar(false);
                              props.setShowPreLeftSidebar(false);
                            }
                          }}
                          to="/dashboard/cashier"
                        >
                          <div className="icon">
                            <i className="fas fa-wallet"></i>
                          </div>
                          <div className="text">{__("Cashier")}</div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
                {!props.isAuthenticated && (
                  <>
                    <Link
                      onClick={() => {
                        props.setModalLoginPropsData(true);
                        setTimeout(function () {
                          props.setModalLoginPropsData(false);
                        }, 500);
                      }}
                      className="common_btn  LogInBtn"
                    >
                      {__("Log In")} | {__("Sign Up")}
                    </Link>
                    <br />
                  </>
                )}
              </div>
            </div>
            <div className="pre_menu_area">
              <ul>
                <li className={"homeMenu " + (location.pathname === "/" ? "active" : "")}>
                  <Link
                    onClick={() => {
                      if (props.isMobileDevice) {
                        setShowSidebar(false);
                        props.setShowPreLeftSidebar(false);
                      }
                    }}
                    to="/"
                  >
                    <div className="icon"></div>
                    <div className="text">{__("Home")}</div>
                  </Link>
                </li>
                {false && ( //Disabled for now
                  <>
                    <li className={"slotsMenu " + (location.pathname === "/lobby/vivo" ? "active" : "")}>
                      <Link
                        onClick={() => {
                          if (props.isMobileDevice) {
                            setShowSidebar(false);
                            props.setShowPreLeftSidebar(false);
                          }
                        }}
                        to="/lobby/vivo"
                      >
                        <div className="icon">
                          {/* <img
                        className=""
                        src={
                          require("../assets/images/common/slots.svg").default
                        }
                      /> */}
                        </div>
                        <div className="text">{__("Vivo Gaming Lobby")}</div>
                      </Link>
                    </li>
                    <li className={"slotsMenu " + (location.pathname === "/lobby/7mojos" ? "active" : "")}>
                      <Link
                        onClick={() => {
                          if (props.isMobileDevice) {
                            setShowSidebar(false);
                            props.setShowPreLeftSidebar(false);
                          }
                        }}
                        to="/lobby/7mojos"
                      >
                        <div className="icon">
                          {/* <img
                        className=""
                        src={
                          require("../assets/images/common/slots.svg").default
                        }
                      /> */}
                        </div>
                        <div className="text">{__("7Mojos Lobby")}</div>
                      </Link>
                    </li>
                    <li className={"slotsMenu " + (location.pathname === "/lobby/leap" ? "active" : "")}>
                      <Link
                        onClick={() => {
                          if (props.isMobileDevice) {
                            setShowSidebar(false);
                            props.setShowPreLeftSidebar(false);
                          }
                        }}
                        to="/lobby/leap"
                      >
                        <div className="icon">
                          {/* <img
                        className=""
                        src={
                          require("../assets/images/common/slots.svg").default
                        }
                      /> */}
                        </div>
                        <div className="text">{__("Leap Lobby")}</div>
                      </Link>
                    </li>
                    <li className={"slotsMenu " + (location.pathname === "/lobby/boongo" ? "active" : "")}>
                      <Link
                        onClick={() => {
                          if (props.isMobileDevice) {
                            setShowSidebar(false);
                            props.setShowPreLeftSidebar(false);
                          }
                        }}
                        to="/lobby/boongo"
                      >
                        <div className="icon">
                          {/* <img
                        className=""
                        src={
                          require("../assets/images/common/slots.svg").default
                        }
                      /> */}
                        </div>
                        <div className="text">{__("Boongo Lobby")}</div>
                      </Link>
                    </li>

                    <li className={"slotsMenu " + (location.pathname === "/lobby/platipus" ? "active" : "")}>
                      <Link
                        onClick={() => {
                          if (props.isMobileDevice) {
                            setShowSidebar(false);
                            props.setShowPreLeftSidebar(false);
                          }
                        }}
                        to="/lobby/platipus"
                      >
                        <div className="icon">
                          {/* <img
                        className=""
                        src={
                          require("../assets/images/common/slots.svg").default
                        }
                      /> */}
                        </div>
                        <div className="text">{__("Platipus Lobby")}</div>
                      </Link>
                    </li>

                    <li className={"slotsMenu " + (location.pathname === "/lobby/playson" ? "active" : "")}>
                      <Link
                        onClick={() => {
                          if (props.isMobileDevice) {
                            setShowSidebar(false);
                            props.setShowPreLeftSidebar(false);
                          }
                        }}
                        to="/lobby/playson"
                      >
                        <div className="icon">
                          {/* <img
                        className=""
                        src={
                          require("../assets/images/common/slots.svg").default
                        }
                      /> */}
                        </div>
                        <div className="text">{__("Playson Lobby")}</div>
                      </Link>
                    </li>

                    <li className={"slotsMenu " + (location.pathname === "/lobby/redrake" ? "active" : "")}>
                      <Link
                        onClick={() => {
                          if (props.isMobileDevice) {
                            setShowSidebar(false);
                            props.setShowPreLeftSidebar(false);
                          }
                        }}
                        to="/lobby/redrake"
                      >
                        <div className="icon">
                          {/* <img
                        className=""
                        src={
                          require("../assets/images/common/slots.svg").default
                        }
                      /> */}
                        </div>
                        <div className="text">{__("RedRake Lobby")}</div>
                      </Link>
                    </li>

                    <li className={"slotsMenu " + (location.pathname === "/lobby/tomhorn" ? "active" : "")}>
                      <Link
                        onClick={() => {
                          if (props.isMobileDevice) {
                            setShowSidebar(false);
                            props.setShowPreLeftSidebar(false);
                          }
                        }}
                        to="/lobby/tomhorn"
                      >
                        <div className="icon"></div>
                        <div className="text">{__("Tomhorn Lobby")}</div>
                      </Link>
                    </li>
                  </>
                )}
                <li className={"allGamesMenu " + (location.pathname === "/games/all" ? "active" : "")}>
                  <Link
                    onClick={() => {
                      if (props.isMobileDevice) {
                        setShowSidebar(false);
                        props.setShowPreLeftSidebar(false);
                      }
                    }}
                    to="/games/all"
                  >
                    <div className="icon"></div>
                    <div className="text">{__("All Games")}</div>
                  </Link>
                </li>
                <li className={"slotsMenu " + (location.pathname === "/games/slots" ? "active" : "")}>
                  <Link
                    onClick={() => {
                      if (props.isMobileDevice) {
                        setShowSidebar(false);
                        props.setShowPreLeftSidebar(false);
                      }
                    }}
                    to="/games/slots"
                  >
                    <div className="icon">
                      {/* <img
                        className=""
                        src={
                          require("../assets/images/common/slots.svg").default
                        }
                      /> */}
                    </div>
                    <div className="text">{__("Slots")}</div>
                  </Link>
                </li>
                <li className={"crashGamesMenu " + (location.pathname === "/games/crash-games" ? "active" : "")}>
                  <Link
                    onClick={() => {
                      if (props.isMobileDevice) {
                        setShowSidebar(false);
                        props.setShowPreLeftSidebar(false);
                      }
                    }}
                    to="/games/crash-games"
                  >
                    <div className="icon"></div>
                    <div className="text">{__("Crash Games")}</div>
                  </Link>
                </li>
                <li className={"casinoGamesMenu " + (location.pathname === "/games/table-games" ? "active" : "")}>
                  <Link
                    onClick={() => {
                      if (props.isMobileDevice) {
                        setShowSidebar(false);
                        props.setShowPreLeftSidebar(false);
                      }
                    }}
                    to="/games/table-games"
                  >
                    <div className="icon"></div>
                    <div className="text">{__("Table Games")}</div>
                  </Link>
                </li>
                <li className={"liveCasinoMenu " + (location.pathname === "/games/live-casino" ? "active" : "")}>
                  <Link
                    onClick={() => {
                      if (props.isMobileDevice) {
                        setShowSidebar(false);
                        props.setShowPreLeftSidebar(false);
                      }
                    }}
                    to="/games/live-casino"
                  >
                    <div className="icon"></div>
                    <div className="text">{__("Live Casino")}</div>
                  </Link>
                </li>
                {/* <li className={"jackpotsMenu  " + (location.pathname === "/games/jackpots" ? "active" : "")}>
                  <Link
                    onClick={() => {
                      if (props.isMobileDevice) {
                        setShowSidebar(false);
                        props.setShowPreLeftSidebar(false);
                      }
                    }}
                    to="/games/jackpots"
                  >
                    <div className="icon"></div>
                    <div className="text">{__("Jackpots")}</div>
                  </Link>
                </li> */}
                <li className={"newGamesMenu " + (location.pathname === "/games/news" ? "active" : "")}>
                  <Link
                    onClick={() => {
                      if (props.isMobileDevice) {
                        setShowSidebar(false);
                        props.setShowPreLeftSidebar(false);
                      }
                    }}
                    to="/games/new"
                  >
                    <div className="icon"></div>
                    <div className="text">{__("New Games")}</div>
                  </Link>
                </li>

                <li className="zinoExclusiveMenu d-none">
                  <Link to="/games/zino-exclusive">
                    <div className="icon"></div>
                    <div className="text">{__("Zino Exclusive")}</div>
                  </Link>
                </li>
              </ul>

              <ul>
                <li>
                  <Link className="store_menu" to="/store">
                    <div className="icon">
                      <img
                        src={require("../assets/images/icons/store.svg").default}
                        style={{ width: "22px", marginTop: "-4px", opacity: "1" }}
                      />
                    </div>
                    <span className="text">{__("Store")}</span>
                  </Link>
                </li>
                <li className={"promotoinsMenu " + (location.pathname === "/promotions" ? "active" : "")}>
                  <Link to="/promotions">
                    <div className="icon">
                      {/* <img
                        className=""
                        src={
                          require("../assets/images/common/promotions.svg")
                            .default
                        }
                      /> */}
                    </div>
                    <div className="text">{__("Promotions")}</div>
                  </Link>
                </li>
                <li className={"vipClubMenu " + (location.pathname === "/vip" ? "active" : "")}>
                  <Link to="/vip">
                    <div className="icon">
                      {/* <img
                        className=""
                        src={
                          require("../assets/images/common/vip.svg")
                            .default
                        }
                      /> */}
                    </div>
                    <div className="text">{__("VIP Lounge")}</div>
                  </Link>
                </li>
              </ul>
            </div>

            <div className="clock_area">
              <Clock format="HH:mm:ss" interval={1000} ticking={true} />
            </div>
            <br />
            {/* <div className="rotate_text">{__("Casino Bull")}</div> */}
          </div>
        </div>
      </div>
      {/* {showSidebar && (
        <div
          className="invisible_overlay"
          onClick={() => setShowSidebar(false)}
        ></div>
      )} */}
    </>
  );
}
