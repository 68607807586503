import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "services/auth";
import ls from "local-storage";
import AppContext from "../contexts/AppContext";
import api from "services/api";
import GameItem from "elements/games_elements/GameItem";
import emailValidate from "services/emailValidate";
import { Badge, Button } from "react-bootstrap";
import Slider from "react-slick";
import { __ } from "services/Translator";
const Banner = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    arrows: false,
    // slidesToShow: 1,
    // slidesToScroll: 1,
  };
  const settings2 = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  //Get Banners
  const [banners, setBanners] = useState([]);
  const getBanners = async (ev) => {
    var response = await api.get("/banners");
    setBanners(response.data);
  };

  useEffect(() => {
    getBanners();
  }, []);

  const [gameList, setGameList] = useState(null);
  const getGameList = async (page) => {
    props.setSpinner(true);
    var per_page = 6;
    if (window.innerWidth < 992) {
      per_page = 4;
    }
    if (window.innerWidth < 768) {
      per_page = 2;
    }
    var response = await api.post("/games/by-category", {
      per_page: per_page,
      game_types: ["popular"],
      page: 1,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      setGameList(response.data.games?.popular?.gamelist ?? []);
    }
  };

  const fixImageRatio = (event) => {
    var img = event.target;
    var width = img.width;
    var height = width * 0.8;
    img.height = height;
    // alert(width)
  };
  return (
    <>
      <div className="landing_banner">
        <Slider {...settings}>
          {banners?.map((banner, index) => (
            // props.setModalSignUpPropsData(true);
            <>
              {!props.isAuthenticated && (
                <>
                  <a
                    href="javascript:"
                    onClick={() => {
                      props.setModalSignUpPropsData(true);
                      setTimeout(function () {
                        props.setModalSignUpPropsData(false);
                      }, 500);
                    }}
                    className="single_banner"
                    key={index}
                  >
                    <img
                      className="banner_image desktop_banner"
                      src={banner.banner}
                    />
                    <img
                      className="banner_image tab_banner"
                      src={banner.medium}
                    />
                    <img
                      className="banner_image mobile_banner"
                      src={banner.small}
                    />
                    <div className="banner_content_area ">
                      <h1 className="banner_title">{banner.title}</h1>
                      <div className="line_border d-none">
                        <ul>
                          <li></li>
                          <li></li>
                          <li></li>
                        </ul>
                      </div>
                      <div className="banner_sub_text">
                        {banner.sub_title}
                        {/* {__("Fortune Casino")}
              <br />
              {__("LIVE CASINO TOURNAMENT")} */}
                      </div>

                      {props.isAuthenticated && (
                        <Link
                          className="common_btn float-left"
                          to="/games/slots"
                        >
                          {__("Play Now!")}
                        </Link>
                      )}

                      {/* {!props.isAuthenticated && (
              <Link
                onClick={() => {
                  props.setModalSignUpPropsData(true);
                  setTimeout(function () {
                    props.setModalSignUpPropsData(false);
                  }, 500);
                }}
                className="common_btn float-left"
              >
                {__("Create an account")}
              </Link>
            )} */}
                    </div>
                  </a>
                </>
              )}
              {props.isAuthenticated && (
                <>
                  <a href={banner.link} className="single_banner" key={index}>
                    <img
                      className="banner_image desktop_banner"
                      src={banner.banner}
                    />
                    <img
                      className="banner_image tab_banner"
                      src={banner.medium}
                    />
                    <img
                      className="banner_image mobile_banner"
                      src={banner.small}
                    />
                    <div className="banner_content_area ">
                      <h1 className="banner_title">{banner.title}</h1>
                      <div className="line_border d-none">
                        <ul>
                          <li></li>
                          <li></li>
                          <li></li>
                        </ul>
                      </div>
                      <div className="banner_sub_text">
                        {banner.sub_title}
                        {/* {__("Fortune Casino")}
              <br />
              {__("LIVE CASINO TOURNAMENT")} */}
                      </div>

                      {props.isAuthenticated && (
                        <Link
                          className="common_btn float-left"
                          to="/games/slots"
                        >
                          {__("Play Now!")}
                        </Link>
                      )}

                      {/* {!props.isAuthenticated && (
              <Link
                onClick={() => {
                  props.setModalSignUpPropsData(true);
                  setTimeout(function () {
                    props.setModalSignUpPropsData(false);
                  }, 500);
                }}
                className="common_btn float-left"
              >
                {__("Create an account")}
              </Link>
            )} */}
                    </div>
                  </a>
                </>
              )}
            </>
          ))}
        </Slider>

        <div className="banner_games_area d-none">
          <div className="game_img_part">
            {gameList &&
              gameList.map((game, index) => (
                // <li>
                //   <Link to={"/play/" + game.slug}>
                //     <img src={game.game_snapshot_url} onLoad={fixImageRatio} />
                //   </Link>
                // </li>
                <GameItem
                  game={game}
                  key={index}
                  showAlert={props.showAlert}
                  {...props}
                />
              ))}
          </div>
          <ul className="game_name_part">
            {gameList &&
              gameList.map((game, index) => (
                <li>
                  <Link key={index} to={"/play/" + game.slug}>
                    {game.game_name}
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Banner;
