import React, { useState, useEffect, useMemo } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "services/auth";
import ls from "services/ls";
import AppContext from "contexts/AppContext";
import InputBox from "elements/InputBox";
import { VipIcon } from "elements/VipLevelText";
import api from "services/api";
import emailValidate from "services/emailValidate";
import { Dropdown } from "react-bootstrap";
import { __ } from "services/Translator";

const Chat = (props) => {
  const visible = props.isChatVisible;

  const closeChat = () => props.setIsChatVisible(!props.isChatVisible);

  const [rooms, setRooms] = useState([]);
  const getRooms = async () => {
    var response = await api.get("/chat/rooms");
    if (response.status === 200) {
      setRooms(response.data);
    }
  };

  const [joinedRoom, setJoinedRoom] = useState(null);
  const [onlineMembers, setOnlineMembers] = useState({ inRoom: 0, total: 0 });
  const joinRoom = async (roomSlug) => {
    var response = await api.get("/chat/room/" + roomSlug);
    if (response.status === 200) {
      if (!response.data.blocked) {
        var response2 = await api.get("/chat/join/" + response.data.id);
        if (response2.status === 200) {
          setJoinedRoom(response.data);
          ls.set("lastChatRoomSlug", response.data.slug);
          return true;
        }
      }
    }
    return false;
  };

  const getOnlineMembers = async (roomSlug) => {
    var response = await api.get("/chat/room/" + roomSlug);
    if (response.status === 200) {
      setOnlineMembers({ inRoom: response.data?.online_members, total: response.data?.online_members_all_rooms });
    }
  }

  const resumeChat = async () => {
    if (rooms.length) {
      var lastChatRoomSlug = ls.get("lastChatRoomSlug");
      if (lastChatRoomSlug) {
        var joined = await joinRoom(lastChatRoomSlug);
        if (!joined) {
        }
      } else {
        await joinRoom(rooms[0].slug);
      }
    }
  };

  var loadingMessages = false;

  const [autoScroll, setAutoScroll] = useState(true);
  const [newMessage, setNewMessage] = useState("");

  const clearMessage = () => {
    setNewMessage("");
  };

  const changeNewMessage = (msg) => {
    if (msg.length >= maxAllowedMessageLength) {
      msg = msg.substr(0, maxAllowedMessageLength);
    }
    setNewMessage(msg);
  };

  const maxAllowedMessageLength = 100;


  const [messages, setMessages] = useState([]);

  const getInitialMessages = async (ev) => {
    if (!loadingMessages) {
      loadingMessages = true;
      var response = await api.post("/chat/messages", {
        roomId: joinedRoom.id,
      });
      if (response.status === 200) {
        setMessages(response.data);
      }
      loadingMessages = false;
    }
  };

  const getNewMessages = async (ev) => {
    if (!loadingMessages) {
      loadingMessages = true;
      var lastId = messages[messages.length - 1]?.id;
      var config = {
        roomId: joinedRoom.id,
      };
      if (lastId) {
        config.after = lastId;
      }
      var response = await api.post("/chat/messages/new", config);
      if (response.status === 200) {
        setMessages([...messages, ...response.data]);
      }

      loadingMessages = false;
    }
  };

  const getOlderMessages = async (ev) => {
    if (!loadingMessages) {
      loadingMessages = true;
      var firstId = messages[0]?.id;
      if (firstId && firstId > 1) {
        //1 means it reached the very first message
        var response = await api.post("/chat/messages/old", {
          roomId: joinedRoom.id,
          before: firstId,
        });
        if (response.status === 200) {
          setMessages([...response.data, ...messages]);
          var element = document.getElementById("messages");
          if (element.scrollTop === 0) {
            element.scrollTo(0, 20);
          }
        }
      }
      loadingMessages = false;
    }
  };

  useEffect(() => {
    getRooms();
  }, []);

  useEffect(() => {
    resumeChat();
  }, [rooms]);

  useEffect(() => {
    if (autoScroll) {
      var element = document.getElementById("messages");
      element.scrollTo(0, element.scrollHeight);
    }
  }, [messages.length]);

  useEffect(() => {
    if (joinedRoom?.id) {
      getInitialMessages();
    }
  }, [joinedRoom]);

  useEffect(() => {
    if (joinedRoom?.id) {
      const interval = setInterval(() => {
        getNewMessages();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [joinedRoom, messages.length]);

  useEffect(() => {
    if (joinedRoom?.slug) {
      const interval = setInterval(() => {
        getOnlineMembers(joinedRoom?.slug);
      }, 20000);
      return () => clearInterval(interval);
    }
  }, [joinedRoom]);

  const disableAutoScrollOnScrollUp = (event) => {
    var element = event.target;
    if (element.scrollHeight - element.scrollTop - element.clientHeight > 10) {
      setAutoScroll(false);
    } else {
      setAutoScroll(true);
    }
  };

  const loadOlderMessagesOnScrollUp = (event) => {
    var element = event.target;
    if (element.scrollTop < 20) {
      getOlderMessages();
    }
  };

  useEffect(() => {
    var element = document.getElementById("messages");
    element.addEventListener("scroll", loadOlderMessagesOnScrollUp);
    return () => {
      element.removeEventListener("scroll", loadOlderMessagesOnScrollUp);
    };
  }, [joinedRoom, messages.length]);

  useEffect(() => {
    var element = document.getElementById("messages");
    element.addEventListener("scroll", disableAutoScrollOnScrollUp);
    return () => {
      element.removeEventListener("scroll", disableAutoScrollOnScrollUp);
    };
  }, []);

  const readSocket = (event) => {
    var msg = JSON.parse(event.data);
    var msgData = msg.data;
    if (msg.type === "chat_message" && msgData.room_id === joinedRoom?.id) {
      var msgs = JSON.parse(JSON.stringify(messages));
      msgs.push(msg.data);
      setMessages(msgs);
    }
  };

  useEffect(() => {
    // props.socket.addEventListener("message", readSocket);
    // return () => {
    //   props.socket.removeEventListener("message", readSocket);
    // };
  }, [joinedRoom, messages.length]);

  const popUpChat = () => {
    var chatWindow = window.open(
      "/chat",
      "chatWindow",
      "width=400,height=600,directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0"
    );
    props.setChatInPopup(chatWindow);
    closeChat();
  };

  const sendMessage = async (event) => {
    try {
      event.preventDefault();
      if (newMessage.length) {
        var response = await api.post("/chat/message/send", {
          message: newMessage,
          roomId: joinedRoom.id,
        });
        if (response.status === 200) {
          clearMessage();
          setAutoScroll(true);
          getNewMessages();
        } else {
          props.showAlert("Warning!!", response.data.message, true);
        }
      }
    } catch (err) { }
  };

  const [collapsesOpen, setCollapsesOpen] = useState(false);
  const toggleCollapse = () => {
    setCollapsesOpen(!collapsesOpen);
  };

  return (
    <>
      <section
        className={
          "chat_window " +
          (visible ? "" : "hidden ") +
          (props.fullLayout ? "full_layout" : "")
        }
      >
        <div className="heading">
          <Dropdown>
            <Dropdown.Toggle className="right rooms_dropdown">
            {__("Chat")} : {joinedRoom?.name} ({joinedRoom?.online_members ?? 0}{" "}{__("online")})
              <div className="caret">
                <i className="fal downarrow fa-caret-down"></i>
                <i className="fal uparrow fa-caret-up"></i>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="rooms_menu desktop">
              <div className="modal_top_arrow"></div>
              <div>
                {rooms.map((item, index) => (
                  <Dropdown.Item
                    as="button"
                    key={index}
                    onClick={() => joinRoom(item.slug)}
                  >
                    {item.name}
                  </Dropdown.Item>
                ))}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {!props.fullLayout && (
          <>
            <a className="popup_chat" onClick={popUpChat}>
              <i className="far fa-external-link"></i>
            </a>
            <a className="close_chat" onClick={closeChat}>
              <i className="far fa-times"></i>
            </a>
          </>
        )}
        <div className="chat_box">
          <div className="messages" id="messages">
            {messages.map((item, index) => (
              <div
                key={item.id}
                className={"single_message " + (item.own ? "own" : "")}
              >
                <div className="arrow"></div>
                <div className="message_row">
                  <div className="chat_user">
                    <div className="avatar"></div>
                    <div className="user_name">
                      <VipIcon identity={item.sender?.identity} />{" "}
                      {item.sender?.name}:&nbsp;
                    </div>
                  </div>
                  <div className="chat_message">{item.message}</div>
                </div>
                <div className="bottom_row">
                  <div className="chat_time">
                    {" - "}
                    {item.sent_at_formatted}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <form className="new_message" onSubmit={sendMessage}>
            <InputBox
              type="text"
              className="new_message_input"
              onChange={(value) => setNewMessage(value)}
              value={newMessage}
            />
<span className="remaining_characters">
              ({maxAllowedMessageLength - newMessage.length})
            </span>
            <button type="submit" className="new_message_submit">
              <i className="far fa-paper-plane"></i>
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default Chat;
