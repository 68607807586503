import React, { useState, useEffect, useRef } from "react";

import { __ } from "services/Translator";
import api from "services/api";
import { Modal, Accordion, Popover, OverlayTrigger, Tooltip, Overlay } from "react-bootstrap";
import { isMobile } from "services/isMobile";
import { Link, useParams } from "react-router-dom";
import DOMPurify from "dompurify";

export default function FiatCashier(props) {
  const defaultDepositAmounts = [10, 20, 50, 100, 200, 500, 1000, 5000];
  const overriddenDepositAmounts = {
    MXN: [100, 200, 500, 1000, 2000, 5000, 10000, 50000],
    USD: [10, 20, 50, 100, 200, 500, 1000, 5000],
  };
  const params = useParams();
  const linkDepositBtnWithPraxis = true;

  const praxisIframeHeight = props.isMobileDeviceByViewPort ? 1150 : 950;

  const personalIdToolTip = useRef(null);
  const [personalIdToolTipShow, setPersonalIdToolTipShow] = useState(false);

  const [depositAmounts, setDepositAmounts] = useState(defaultDepositAmounts);
  const [depositAmount, setDepositAmount] = useState(10);
  const [isCustomAmount, setIsCustomAmount] = useState(false);
  const [depositMethods, setDepositMethods] = useState([]);
  const [depositMethod, setDepositMethod] = useState(null);
  const [bonusCampaigns, setBonusCampaigns] = useState([]);
  const [bonusCampaign, setBonusCampaign] = useState("no_bonus");
  const [depositFinalData, setDepositFinalData] = useState(null);
  const [infoUrl, setInfoUrl] = useState("");

  const isAuthenticated = props.isAuthenticated;
  const userDetails = props.userDetails;
  const userBalance = props.userBalance;

  const [depositForm, setDepositForm] = useState({});
  const [depositModalOpen, setDepositModalOpen] = useState(false);
  const [deleteBonusModalOpen, setDeleteBonusModalOpen] = useState(false);
  const [customDepositAmountOpen, setCustomDepositAmountOpen] = useState(true);
  const [depositFinalModalOpen, setDepositFinalModalOpen] = useState(false);
  const [readCarefullyModalOpen, setReadCarefullyModalOpen] = useState(false);

  const [creditCardInfo, setCreditCardInfo] = useState({
    NameOnCC: "",
    CardNumber: "",
    ExpMonth: "",
    ExpYear: "",
    CVV: "",
  });

  const [regErrors, setRegErrors] = useState({
    NameOnCC: "",
    CardNumber: "",
    ExpMonth: "",
    ExpYear: "",
    CVV: "",
  });

  useEffect(() => {
    if (userBalance?.currency_code) {
      let currency = userBalance?.currency_code;
      if (overriddenDepositAmounts[currency] !== undefined) {
        setDepositAmounts(overriddenDepositAmounts[currency]);
      }
    }
  }, [userBalance?.currency_code]);

  const toggleDepositModal = async () => {
    if (linkDepositBtnWithPraxis) {
      var postData = {
        amount: 25123, //Dummy amount. Actual amount will be on praxis's hosted page
        method: "Praxis:Cashier",
        campaign: bonusCampaign,
      };
      props.setSpinner(true);
      var response = await api.post("/deposit", postData);
      props.setSpinner(false);
      if (response.status === 200) {
        getDepositCampaigns();
        getNextDepositBonus();
        if (response.data.status === "additional_fields" && response.data.fields_for === "webpayment") {
          setDepositModalOpen(false);
          setDepositFinalData(response.data.data);
          setDepositFinalModalOpen(true);
        } else if (response.data.status === "redirect") {
          // window.location.href = response.data.redirectUrl;
          setDepositModalOpen(false);
          setDepositFinalData({ invoice_url: response.data.redirectUrl });
          setDepositFinalModalOpen(true);
        } else {
          props.showAlert(__("Warning!!"), __(response.data.message), true);
        }
      } else {
        props.showAlert(__("Warning!!"), __(response.data.message), true);
      }
    } else {
      if (!depositModalOpen) {
        getDepositMethods();
      }
      setDepositModalOpen(!depositModalOpen);
    }
  };

  const toggleDeleteBonusModalOpen = () => {
    setDeleteBonusModalOpen(!deleteBonusModalOpen);
  };

  const toggleReadCarefullyModal = () => {
    setReadCarefullyModalOpen(!readCarefullyModalOpen);
  };

  const toggleDepositFinalModalOpen = () => {
    setDepositFinalModalOpen(!depositFinalModalOpen);
  };

  useEffect(() => {
    getDepositMethods();
  }, [depositAmount]);

  useEffect(() => {
    getMinimumDepositAmount();
    if (params.extraParam1 && params.extraParam1 === "deposit") {
      toggleDepositModal();
    }
  }, []);

  const getMinimumDepositAmount = async () => {
    var response = await api.get("/deposit/get-bonus-eligible-deposit-amount");
    if (response.status === 200) {
      setDepositAmount(response.data.minimum_deposit);
      console.log("getMinimumDepositAmount", response.data);
    }
  };

  const [depositMethodsLoaded, setDepositMethodsLoaded] = useState(false);

  //Fetches the available deposit methods and campaigns based on the deposit amount
  //Used if deposit button is not directly linked to praxis
  const getDepositMethods = async () => {
    var response = await api.post("/deposit/methods", {
      amount: depositAmount,
    });
    if (response.status === 200) {
      if (!depositMethodsLoaded && response.data.methods.length) {
        //So that methods doesn't change every time amount is changed, but only loaded once
        setDepositMethodsLoaded(true);
        setDepositMethods(response.data.methods);

        if (!response.data.methods.filter((method) => method.method_id === depositMethod).length) {
          setDepositMethod(null);
        }
        setDepositMethod(response.data?.methods[0].method_id); //Selected deafult payment method
      }
      setBonusCampaigns(response.data.campaigns);
      if (!response.data.campaigns.filter((campaign) => campaign.bonus_campaign.id === bonusCampaign).length) {
        setBonusCampaign("no_bonus");
      }
    }
  };

  //Fetches the available campaigns based on the deposit amount
  //Used if deposit button directly linked to praxis
  const getDepositCampaigns = async () => {
    var response = await api.post("/deposit/campaigns", {
      amount: 5000, //Dummy amount, high enough to get all campaigns
    });
    if (response.status === 200) {
      setBonusCampaigns(response.data.campaigns);
    }
  };

  const getNextDepositBonus = async (campaignId) => {
    var response = await api.get("/deposit/get-next-bonus", { campaign_id: campaignId });
    if (response.status === 200) {
      if (response.data) {
        var campaign = parseInt(response.data);
        if (isNaN(campaign)) {
          campaign = "no_bonus";
        }
        setBonusCampaign(campaign);
      } else {
        setBonusCampaign("no_bonus");
      }
    }
  };

  const updateNextDepositBonus = async (campaignId) => {
    var prevCampaignId = bonusCampaign;
    setBonusCampaign(campaignId);
    var response = await api.post("/deposit/save-next-bonus", { campaign_id: campaignId });
    if (response.status !== 200) {
      setBonusCampaign(prevCampaignId);
    }
  };

  const [personalId, setPersonalId] = useState({
    show: false,
    id: "",
    country: "",
  });

  const updatePersonalId = (ev) => {
    var value = ev.target.value;
    if (personalId.country == "BR") {
      value = value.replace(/[^\d]+/g, "");
    } else {
      value = value.replace(/[\W]+/g, " ");
    }
    setPersonalId({ ...personalId, id: value });
  };

  const getShowPersonalId = async () => {
    if (depositMethod && depositMethod.indexOf("PayRetailer:") === 0) {
      var response = await api.post("/payretailer/is-personal-id-required", { method: depositMethod });
      if (response.status === 200) {
        if (response.data.show == true) {
          setPersonalId(response.data);
        } else {
          setPersonalId({ ...personalId, show: false });
        }
      } else {
        setPersonalId({ ...personalId, show: false });
      }
    } else {
      setPersonalId({ ...personalId, show: false });
    }
  };

  useEffect(() => {
    getShowPersonalId();
  }, [depositMethod]);

  const submitDeposit = async (event) => {
    event.preventDefault();
    props.setSpinner(true);

    if (depositMethod == "BzPay:CC") {
      var valid = validateCCForm();
      if (!valid) {
        props.setSpinner(false);
        return;
      }
    }

    var postData = {
      amount: depositAmount,
      // amount: 1,
      method: depositMethod,
      campaign: bonusCampaign,
    };

    if (depositMethod == "BzPay:CC") {
      postData.creditCardInfo = creditCardInfo;
    }

    if (depositMethod.indexOf("PayRetailer:") === 0) {
      postData.personalId = personalId.id;
    }

    var response = await api.post("/deposit", postData);
    props.setSpinner(false);
    if (response.status === 200) {
      if (response.data.status === "APPROVED" && response.data.fields_for === "BzPay:CC") {
        // setDepositFinalData(response.data.data);

        props.showAlert(__("Success"), __(response.data.message));

        setCreditCardInfo({
          NameOnCC: "",
          CardNumber: "",
          ExpMonth: "",
          ExpYear: "",
          CVV: "",
        });
        setDepositModalOpen(false);
      } else if (response.data.status === "additional_fields" && response.data.fields_for === "webpayment") {
        setDepositModalOpen(false);
        setDepositFinalData(response.data.data);
        setDepositFinalModalOpen(true);
      } else if (response.data.status === "redirect") {
        // window.location.href = response.data.redirectUrl;
        setDepositModalOpen(false);
        setDepositFinalData({ invoice_url: response.data.redirectUrl });
        setDepositFinalModalOpen(true);
      } else {
        props.showAlert(__("Warning!!"), __(response.data.message), true);
      }
    } else {
      props.showAlert(__("Warning!!"), __(response.data.message), true);
    }
  };

  //Mobile Device detection
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  useEffect(() => {
    setIsMobileDevice(isMobile());
    setDepositFinalData(null);
  }, []);

  const updateCCInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;

    setCreditCardInfo({ ...creditCardInfo, [field]: value });
  };

  const validateCCForm = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};
    // alert(44);

    if (creditCardInfo.NameOnCC.length < 2 || creditCardInfo.NameOnCC.length > 250) {
      errorsTmp = { ...errorsTmp, NameOnCC: __("Please enter the name") };
      valid = false;
    }

    if (creditCardInfo.CardNumber.length < 2 || creditCardInfo.CardNumber.length > 250) {
      errorsTmp = {
        ...errorsTmp,
        CardNumber: __("Please enter the card number"),
      };
      valid = false;
    }

    if (creditCardInfo.CardNumber.length < 2 || creditCardInfo.CardNumber.length > 250) {
      errorsTmp = {
        ...errorsTmp,
        CardNumber: __("Please enter the card number"),
      };
      valid = false;
    }

    if (creditCardInfo.ExpMonth.length < 1) {
      errorsTmp = {
        ...errorsTmp,
        ExpMonth: __("Please enter the expiry month"),
      };
      valid = false;
    }

    if (creditCardInfo.ExpYear.length < 1) {
      errorsTmp = {
        ...errorsTmp,
        ExpYear: __("Please enter the expiry year"),
      };
      valid = false;
    }

    if (creditCardInfo.CVV.length < 1) {
      errorsTmp = {
        ...errorsTmp,
        CVV: __("Please enter the CVV"),
      };
      valid = false;
    }

    setRegErrors(errorsTmp);
    return valid;
  };

  return (
    <>
      <div className="button_group">
        <Link onClick={toggleDepositModal} className="common_btn uppercase no_margin" id="Deposit">
          {__("Deposit")}
        </Link>
        <Link to="/dashboard/withdraw" className="common_btn uppercase  no_margin">
          {__("Withdraw")}
        </Link>
      </div>
      <Modal className="cashierModal" size="lg" show={depositModalOpen} onHide={toggleDepositModal}>
        <Modal.Header className="modal-header">
          <h5 className="modal-title">{__("Deposit")}</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={toggleDepositModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="">
          <div className="deposit-methods">
            <div className="deposit-method">
              <div className="alert d-none alert-danger"></div>
              <form method="post" id="internalDepositForm" className="depositform" action={""} onSubmit={submitDeposit}>
                <div className="modal_amount_area">
                  <div className="chimp-radio-field d-none">
                    {depositAmounts.map((val, index) => (
                      <div className="cashier_amount_lavel" key={index} style={{ marginRight: 10 }}>
                        <input
                          type="radio"
                          className="deposit_campaign_check"
                          id={"fiat_amount_selector" + val}
                          onChange={(ev) => {
                            setDepositAmount(val);
                            setCustomDepositAmountOpen(false);
                          }}
                          checked={depositAmount === val}
                          value={val}
                        />

                        <label for={"fiat_amount_selector" + val} className="radio_area">
                          <div className="radio_check_and_text_area">
                            <div className="radio_box">
                              <div className="radio_checked_box"></div>
                            </div>
                            <div className="radio_text">
                              <span className="currency">{val}</span>
                            </div>
                          </div>
                        </label>
                      </div>
                    ))}
                    <div className="cashier_amount_lavel d-none">
                      <input
                        type="radio"
                        id="CustomDepositAmount"
                        className="deposit_campaign_check"
                        onChange={(ev) => {
                          setDepositAmount(depositAmount.toString());
                          // setCustomDepositAmountOpen(ev.target.checked);
                        }}
                        checked={customDepositAmountOpen}
                      />
                      <label for={"CustomDepositAmount"} className="radio_area">
                        <div className="radio_check_and_text_area">
                          <div className="radio_box">
                            <div className="radio_checked_box"></div>
                          </div>
                          <div className="radio_text">
                            <span className="currency">{__("Custom Amount")}</span>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div></div>
                </div>

                <strong>{__("Payment Method")}</strong>
                <div className="reg-field chimp-select-field payment_method_list">
                  <div className="banking_area">
                    <div className="banking_list_area">
                      {depositMethods.map((method, index) => (
                        <>
                          {method.method_id.indexOf("WEBPAYMENT:") > -1 ? (
                            ""
                          ) : (
                            <div className={"single_banking e_wallet_method "}>
                              <input
                                id={"all_" + method.id}
                                onChange={() => {
                                  setDepositMethod(method.method_id);
                                  // alert(method.method_id);
                                }}
                                type="radio"
                                checked={method.method_id === depositMethod}
                              />
                              <label htmlFor={"all_" + method.id}>
                                <ul>
                                  <li className="payment_logo_area">
                                    <span className="helper"></span>
                                    <span className="payment_text_mobile">
                                      <span className="helper"></span>
                                      <span className="method_logo_text">{__("Method Logo")}</span>
                                    </span>
                                    <div
                                      className="method_logo"
                                      style={{
                                        backgroundImage: "url(" + method.logo + ")",
                                      }}
                                    ></div>
                                    <div className="clearfix"></div>
                                  </li>
                                  <li className="payment_method_name">
                                    <span className="helper"></span>
                                    <span className="payment_text_mobile">{__("Method Name")}</span>
                                    <span className="payment_content"> {method.method}</span>
                                    <div className="clearfix"></div>
                                  </li>
                                  <li className="min_payment_amount">
                                    <span className="payment_text_mobile">{__("Limit Amount")}</span>
                                    {/* <span className="payment_content">
                                      {__("MIN")} {method.min_deposit} USD
                                    </span> */}
                                    <div className="clearfix"></div>
                                    <span className="payment_content">
                                      {__("Minimum amount of deposit is ") +
                                        " " +
                                        method.min_deposit_in_player_currency}
                                    </span>
                                  </li>
                                </ul>
                              </label>
                              <label htmlFor={"all_" + method.id} className="payment_check_mark">
                                <i className="fas fa-check selected_method_icon"></i>
                              </label>
                            </div>
                          )}
                        </>
                      ))}

                      <div className="d-none chimp-accordions access_control_area payment_methods_accordions">
                        <Accordion>
                          <Accordion.Item key={0} eventKey={0} className="chimp-accordion">
                            <Accordion.Header className="faq__question">{__("Crypto")}</Accordion.Header>
                            <Accordion.Body className="faq__answer">
                              {depositMethods.map((method, index) => (
                                <>
                                  {method.method_id.indexOf("WEBPAYMENT:") > -1 ? (
                                    <div className={"single_banking e_wallet_method "}>
                                      <input
                                        id={"all_" + method.id}
                                        onChange={() => {
                                          setDepositMethod(method.method_id);
                                          // alert(method.method_id);
                                        }}
                                        type="radio"
                                        checked={method.method_id === depositMethod}
                                      />
                                      <label htmlFor={"all_" + method.id}>
                                        <ul>
                                          <li className="payment_logo_area">
                                            <span className="helper"></span>
                                            <span className="payment_text_mobile">
                                              <span className="helper"></span>
                                              <span className="method_logo_text">{__("Method Logo")}</span>
                                            </span>
                                            <div
                                              className="method_logo"
                                              style={{
                                                backgroundImage: "url(" + method.logo + ")",
                                              }}
                                            ></div>
                                            <div className="clearfix"></div>
                                          </li>
                                          <li className="payment_method_name">
                                            <span className="helper"></span>
                                            <span className="payment_text_mobile">{__("Method Name")}</span>
                                            <span className="payment_content"> {method.method}</span>
                                            <div className="clearfix"></div>
                                          </li>
                                          <li className="min_payment_amount">
                                            <span className="payment_text_mobile">{__("Limit Amount")}</span>
                                            {/* <span className="payment_content">
                                              {__("MIN")} {method.min_deposit} USD
                                            </span> */}
                                            <div className="clearfix"></div>
                                            <span className="payment_content">
                                              {__("Minimum amount of deposit is ") +
                                                " " +
                                                method.min_deposit_in_player_currency}
                                            </span>
                                          </li>
                                        </ul>
                                      </label>
                                      <label htmlFor={"all_" + method.id} className="payment_check_mark">
                                        <i className="fas fa-check selected_method_icon"></i>
                                      </label>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ))}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>

                {customDepositAmountOpen && (
                  <>
                    <strong>{__("Amount")}</strong>
                    <div className="form_area" style={{ marginTop: "0px" }}>
                      <div className="input_item">
                        <input
                          type="number"
                          className="custom_ut_value form-control name_value "
                          placeholder="Amount"
                          value={depositAmount.toString()}
                          onChange={(ev) => setDepositAmount(parseInt(ev.target.value).toString())}
                        />
                        <br />
                      </div>
                    </div>
                  </>
                )}

                <div className={"credit_card_from_area " + (depositMethod == "BzPay:CC" ? "d-block" : "d-none")}>
                  <div className="form_area">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input_item">
                          <div className="name">
                            {__("Card Name")}
                            <span className="requried_icon">*</span>
                          </div>
                          <input
                            type="text"
                            name="NameOnCC"
                            class={"form-control name_value "}
                            value={creditCardInfo.NameOnCC}
                            onChange={updateCCInput}
                          />
                          <div className="errorMsg">{regErrors.NameOnCC}</div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input_item">
                          <div className="name">
                            {__("Card Number")}
                            <span className="requried_icon">*</span>
                          </div>
                          <input
                            type="number"
                            name="CardNumber"
                            class={"form-control name_value "}
                            value={creditCardInfo.CardNumber}
                            onChange={updateCCInput}
                          />
                          <div className="errorMsg">{regErrors.CardNumber}</div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input_item">
                          <div className="name">
                            {__("Expiry Month")}
                            <span className="requried_icon">*</span>
                          </div>
                          <input
                            type="number"
                            name="ExpMonth"
                            step="1"
                            min="1"
                            max="12"
                            placeholder="EX: 01"
                            class={"form-control name_value "}
                            value={creditCardInfo.ExpMonth}
                            onChange={updateCCInput}
                          />
                          <div className="errorMsg">{regErrors.ExpMonth}</div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input_item">
                          <div className="name">
                            {__("Expiry Year")}
                            <span className="requried_icon">*</span>
                          </div>
                          <input
                            type="number"
                            name="ExpYear"
                            step="1"
                            min="1900"
                            placeholder="EX: 2022"
                            value={creditCardInfo.ExpYear}
                            onChange={updateCCInput}
                            class={"form-control name_value "}
                          />
                          <div className="errorMsg">{regErrors.ExpYear}</div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input_item">
                          <div className="name">
                            {__("CVV")}
                            <span className="requried_icon">*</span>
                          </div>
                          <input
                            type="number"
                            name="CVV"
                            class={"form-control name_value "}
                            value={creditCardInfo.CVV}
                            onChange={updateCCInput}
                          />
                          <div className="errorMsg">{regErrors.CVV}</div>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>

                <div className={"credit_card_from_area " + (personalId.show ? "d-block" : "d-none")}>
                  <Overlay target={personalIdToolTip.current} show={personalIdToolTipShow} placement="top">
                    {(props) => (
                      <Tooltip id="overlay-example" className="tooltip" {...props}>
                        {__(
                          "To be able to deposit please enter your personal ID. It is a one-time process, only for your first payment. This needs to be done because of the country you are located in. "
                        )}
                      </Tooltip>
                    )}
                  </Overlay>

                  <div className="form_area">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="input_item">
                          <div className="name">
                            {__("Personal ID Number")}
                            <span className="requried_icon">*</span>
                            <div
                              className="help_icon"
                              ref={personalIdToolTip}
                              onMouseOver={() => setPersonalIdToolTipShow(true)}
                              onMouseOut={() => setPersonalIdToolTipShow(false)}
                              style={{ float: "right" }}
                            >
                              ?
                            </div>
                          </div>
                          <input
                            type="text"
                            name="personalId"
                            class={"form-control name_value "}
                            value={personalId.id}
                            onChange={updatePersonalId}
                          />
                          <div className="errorMsg">{regErrors.personalId}</div>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>

                <div className="campaigns-wrap">
                  <label>
                    <h3>{__("Bonus Campaigns")}</h3>
                  </label>
                  <div className="campaigns">
                    {bonusCampaigns.length ? (
                      <>
                        {bonusCampaigns.map((campaign, index) => (
                          <div className="campaign">
                            <input
                              type="radio"
                              id={campaign.uniqid + "_id"}
                              value={campaign.bonus_campaign.id}
                              checked={bonusCampaign === campaign.bonus_campaign.id}
                              onChange={(ev) => setBonusCampaign(campaign.bonus_campaign.id)}
                            />
                            <label htmlFor={campaign.uniqid + "_id"} className="check_box">
                              <i className="fas fa-check"></i>
                            </label>{" "}
                            <label htmlFor={campaign.uniqid + "_id"} className="campaign_label">
                              {campaign.bonus_campaign.title}{" "}
                              {campaign.bonus_campaign.campaign_information && (
                                <OverlayTrigger
                                  trigger="hover"
                                  placement="top"
                                  overlay={
                                    <Popover title={__("Campaign Info")}>
                                      <div
                                        style={{ padding: 15 }}
                                        dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(campaign.bonus_campaign.description),
                                        }}
                                      ></div>
                                    </Popover>
                                  }
                                >
                                  <a className="info_popover" href="javascript:">
                                    <i className="fa fa-info-circle" aria-hidden="true"></i>{" "}
                                  </a>
                                </OverlayTrigger>
                              )}
                            </label>
                            <div className="clearfix"></div>
                          </div>
                        ))}
                        <div className="campaign">
                          <input
                            id="no_bonus"
                            checked={bonusCampaign === "no_bonus"}
                            onChange={() => setBonusCampaign("no_bonus")}
                            type="radio"
                            value="no_bonus"
                          />
                          <label htmlFor="no_bonus" className="check_box">
                            <i className="fas fa-check"></i>
                          </label>{" "}
                          <label htmlFor="no_bonus" className="campaign_label">
                            {__("No Bonus")}
                          </label>
                        </div>
                      </>
                    ) : (
                      <div className="campaign">
                        <input
                          id="no_bonus"
                          checked={true}
                          type="radio"
                          onChange={() => setBonusCampaign("no_bonus")}
                        />
                        <label htmlFor="no_bonus" className="check_box">
                          <i className="fas fa-check"></i>
                        </label>
                        {userDetails.acceptsBonus ? (
                          <>
                            <label htmlFor="no_bonus" className="campaign_label">
                              {__("No bonus available")}
                            </label>
                          </>
                        ) : (
                          <label htmlFor="no_bonus" className="campaign_label">
                            {__("You have chosen not to accept bonus")}
                          </label>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="form_error ubonus_campaign"></div>
                <div className="reg-submit">
                  <button type="submit" className=" common_btn uppercase no_margin">
                    {__("Continue")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="cashierModal" size="lg" show={depositFinalModalOpen} onHide={toggleDepositFinalModalOpen}>
        <Modal.Header className="modal-header">
          <h5 className="modal-title" style={{ fontWeight: "bold" }}>
            {__("Complete Deposit")}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={toggleDepositFinalModalOpen}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="">
          {!!depositFinalData && !!depositFinalData.invoice_url && (
            <div
              className="praxis_iframe_container"
              style={{ width: "100%", minHeight: parseInt(praxisIframeHeight + 100) + "px" }}
            >
              <div className="campaigns-wrap">
                <label>
                  <h3 style={{ fontSize: "1.2rem" }}>{__("Bonus Campaigns")}</h3>
                </label>
                <div className="campaigns">
                  {bonusCampaigns.length ? (
                    <>
                      {bonusCampaigns.map((campaign, index) => (
                        <div className="campaign">
                          <input
                            type="radio"
                            id={campaign.uniqid + "_id"}
                            value={campaign.bonus_campaign.id}
                            checked={bonusCampaign === campaign.bonus_campaign.id}
                            onChange={(ev) => updateNextDepositBonus(campaign.bonus_campaign.id)}
                          />
                          <label htmlFor={campaign.uniqid + "_id"} className="check_box">
                            <i className="fas fa-check"></i>
                          </label>{" "}
                          <label htmlFor={campaign.uniqid + "_id"} className="campaign_label">
                            {campaign.bonus_campaign.title} ({__("Minimum deposit for this bonus") + ": "}{" "}
                            {userBalance.currency}
                            {campaign.minimum_deposit})
                            {campaign.bonus_campaign.campaign_information && (
                              <OverlayTrigger
                                trigger="hover"
                                placement="top"
                                overlay={
                                  <Popover title={__("Campaign Info")}>
                                    <div
                                      style={{ padding: 15 }}
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(campaign.bonus_campaign.description),
                                      }}
                                    ></div>
                                  </Popover>
                                }
                              >
                                <a className="info_popover" href="javascript:">
                                  <i className="fa fa-info-circle" aria-hidden="true"></i>{" "}
                                </a>
                              </OverlayTrigger>
                            )}
                          </label>
                          <div className="clearfix"></div>
                        </div>
                      ))}
                      <div className="campaign">
                        <input
                          id="no_bonus"
                          checked={bonusCampaign === "no_bonus"}
                          onChange={() => updateNextDepositBonus("no_bonus")}
                          type="radio"
                          value="no_bonus"
                        />
                        <label htmlFor="no_bonus" className="check_box">
                          <i className="fas fa-check"></i>
                        </label>{" "}
                        <label htmlFor="no_bonus" className="campaign_label">
                          {__("No Bonus")}
                        </label>
                      </div>
                    </>
                  ) : (
                    <div className="campaign">
                      <input
                        id="no_bonus"
                        checked={true}
                        type="radio"
                        onChange={() => updateNextDepositBonus("no_bonus")}
                      />
                      <label htmlFor="no_bonus" className="check_box">
                        <i className="fas fa-check"></i>
                      </label>
                      {userDetails.acceptsBonus ? (
                        <>
                          <label htmlFor="no_bonus" className="campaign_label">
                            {__("No bonus available")}
                          </label>
                        </>
                      ) : (
                        <label htmlFor="no_bonus" className="campaign_label">
                          {__("You have chosen not to accept bonus")}
                        </label>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <iframe
                style={{ width: "100%", minHeight: praxisIframeHeight + "px" }}
                src={depositFinalData.invoice_url}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
