import React, { useState, useEffect, useContext } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "services/auth";
import api from "services/api";
import AppContext from "contexts/AppContext";

import { __ } from "services/Translator";

import { Dropdown, Badge } from "react-bootstrap";
const UserDropDownMenu = (props) => {
  const { updateUserObj } = useContext(AppContext);
  const [defualtShowing, setDefaultShowing] = useState(false);

  const logout = async (ev) => {
    ev.preventDefault();
    await auth.logout();
    await updateUserObj();
  };

  //Verify id checking

  const [level2submitted, setLevel2submitted] = useState(false);
  const [level2Verification, setLevel2Verification] = useState(false);

  const [level3submitted, setLevel3submitted] = useState(false);
  const [level3Verification, setLevel3Verification] = useState(false);

  const [level4submitted, setLevel4submitted] = useState(false);
  const [level4Verification, setLevel4Verification] = useState(false);

  const getIdUploads = async () => {
    var response = await api.get("/id-uploads");
    console.log("id-uploadsid-uploads", response);
    if (response.status === 200 && response.data) {
      if (response.data.poif && response.data.poib) {
        if (response.data.poif.approved == "approved" && response.data.poib.approved == "approved") {
          setLevel2Verification(true);
        } else if (response.data.poif.approved == "submitted" && response.data.poib.approved == "submitted") {
          setLevel2submitted(true);
        }
      }

      if (response.data.poa) {
        if (response.data.poa.approved == "approved") {
          setLevel3Verification(true);
        } else if (response.data.poa.approved == "submitted") {
          setLevel3submitted(true);
        }
      }
      if (response.data.sof) {
        if (response.data.sof.approved == "approved") {
          setLevel4Verification(true);
        } else if (response.data.sof.approved == "submitted") {
          setLevel4submitted(true);
        }
      }
    }
  };

  useEffect(() => {
    if (props.isAuthenticated) {
      getIdUploads();
    }
  }, []);

  const [showMainNotification, setShowMainNotification] = useState(0);
  useEffect(() => {
    if (
      props.freeSpinCount > 0 ||
      props.notifictionCount > 0 ||
      props.messageCount ||
      !level2submitted ||
      !level3submitted
    ) {
      setShowMainNotification(1);
    } else {
      setShowMainNotification(0);
    }
  }, [props]);

  return (
    <>
      <Dropdown show={defualtShowing} className="userDropdownMenu">
        {props.isMobileDevice && (
          <Dropdown.Toggle
            onClick={() => {
              setDefaultShowing(!defualtShowing);
              if (props.isMobileDevice) {
                props.setShowPreLeftSidebar(false);
              }
            }}
            variant="success"
            className="main_notification"
          >
            <i className="far fa-user"></i>
            {showMainNotification > 0 && <div className="notification_round"></div>}
          </Dropdown.Toggle>
        )}
        {!props.isMobileDevice && (
          <Dropdown.Toggle
            onClick={() => {
              setDefaultShowing(!defualtShowing);
            }}
            variant="success"
            className="main_notification"
          >
            <span>
              {props.userDetails.username && props.userDetails.username !== ""
                ? props.userDetails.username
                : props.userDetails.first_name}
            </span>
            {showMainNotification > 0 && <div className="notification_round"></div>}
          </Dropdown.Toggle>
        )}

        <Dropdown.Menu className="dropdown_main_menu">
          <Dropdown.Item href="/dashboard/account-details">
            <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
              <path d="M637 161.1l-19.1-19.2c-4-4.1-10.6-4.1-14.6 0L500.2 245.6l-47.4-47.7c-4-4.1-10.6-4.1-14.6 0L419 217.1c-4 4.1-4 10.6 0 14.7l73.8 74.3c4 4.1 10.6 4.1 14.6 0L637 175.8c4-4 4-10.6 0-14.7zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96zm89.6 256c-28.8 0-42.4 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464z" />
            </svg>
            <span className="menuText">{__("Account Details")}</span>
          </Dropdown.Item>
          <Dropdown.Item href="/dashboard/cashier">
            <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M400 0H48C22.4 0 0 22.4 0 48v416c0 25.6 22.4 48 48 48h352c25.6 0 48-22.4 48-48V48c0-25.6-22.4-48-48-48zm0 464H48V208h352v256zm0-304H48V48h352v112zM108.8 320h38.4c6.4 0 12.8-6.4 12.8-12.8v-38.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zm192 96h38.4c6.4 0 12.8-6.4 12.8-12.8V268.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8zm-192 0h38.4c6.4 0 12.8-6.4 12.8-12.8v-38.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zm96-96h38.4c6.4 0 12.8-6.4 12.8-12.8v-38.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zm0 96h38.4c6.4 0 12.8-6.4 12.8-12.8v-38.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8z" />
            </svg>
            <span className="menuText">
              {__("Cashier")}{" "}
              <Badge className="theme_bg radius_25">
                {props.userBalance.currency}
                {props.userBalance.total_balance}
              </Badge>
            </span>
          </Dropdown.Item>
          <Dropdown.Item href="/dashboard/transaction-history">
            <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M128 152v-32c0-4.4 3.6-8 8-8h208c4.4 0 8 3.6 8 8v32c0 4.4-3.6 8-8 8H136c-4.4 0-8-3.6-8-8zm8 88h208c4.4 0 8-3.6 8-8v-32c0-4.4-3.6-8-8-8H136c-4.4 0-8 3.6-8 8v32c0 4.4 3.6 8 8 8zm299.1 159.7c-4.2 13-4.2 51.6 0 64.6 7.3 1.4 12.9 7.9 12.9 15.7v16c0 8.8-7.2 16-16 16H80c-44.2 0-80-35.8-80-80V80C0 35.8 35.8 0 80 0h352c8.8 0 16 7.2 16 16v368c0 7.8-5.5 14.2-12.9 15.7zm-41.1.3H80c-17.6 0-32 14.4-32 32 0 17.7 14.3 32 32 32h314c-2.7-17.3-2.7-46.7 0-64zm6-352H80c-17.7 0-32 14.3-32 32v278.7c9.8-4.3 20.6-6.7 32-6.7h320V48z" />
            </svg>
            <span className="menuText">{__("Transaction History")}</span>
          </Dropdown.Item>
          <Dropdown.Item href="/dashboard/gaming-history">
            <svg height="22" width="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
              <path d="M370.5 138.9l-50.2-7.3-22.5-45.5c-4-8.1-15.7-8.2-19.7 0l-22.5 45.5-50.2 7.3c-9 1.3-12.6 12.4-6.1 18.8l36.3 35.4-8.6 50c-1.5 8.9 7.9 15.8 16 11.6l44.9-23.6 44.9 23.6c8 4.2 17.5-2.6 16-11.6l-8.6-50 36.3-35.4c6.7-6.4 3-17.5-6-18.8zm-60.3 44.4l5.2 30.6-27.4-14.4-27.5 14.4 5.2-30.6-22.2-21.6 30.7-4.5 13.7-27.8 13.7 27.8 30.7 4.5-22.1 21.6zM448 64V12c0-6.6-5.4-12-12-12H140c-6.6 0-12 5.4-12 12v52H12C5.4 64 0 69.4 0 76v61.6C0 199.7 68.1 272 160.7 285.7c29.4 60.7 73.7 90.3 111.3 96.9V480h-86c-14.4 0-26 11.7-26 26.1 0 3.3 2.7 5.9 6 5.9h244c3.3 0 6-2.6 6-5.9 0-14.4-11.6-26.1-26-26.1h-86v-97.4c37.7-6.6 81.9-36.2 111.3-96.9C508 272 576 199.6 576 137.6V76c0-6.6-5.4-12-12-12H448zM32 137.6V96h96v24c0 51.8 7 94.9 18.5 130.2C77.9 232.5 32 178 32 137.6zM288 352c-72 0-128-104-128-232V32h256v88c0 128-56 232-128 232zm256-214.4c0 40.4-46 94.9-114.5 112.6C441 214.9 448 171.8 448 120V96h96v41.6z" />
            </svg>
            <span className="menuText">{__("Gaming History")}</span>
          </Dropdown.Item>
          <Dropdown.Item className="free_spins_menu notify_area" href="/dashboard/free-spins">
            <img src={require("../assets/images/icons/free-spins.svg").default} />
            <span className="menuText">{__("Free Spins")}</span>
            {props.freeSpinCount > 0 && (
              <>
                <div className="notify_content">
                  <i className="fas fa-bell"></i>
                  <div className="nuber_alart_count">{props.freeSpinCount}</div>
                </div>
              </>
            )}
          </Dropdown.Item>
          {/*<Dropdown.Item
            className="notify_area"
            href="/dashboard/notifications"
          >
            <svg
              height="22"
              width="22"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path d="M439.39 362.29c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71zM67.53 368c21.22-27.97 44.42-74.33 44.53-159.42 0-.2-.06-.38-.06-.58 0-61.86 50.14-112 112-112s112 50.14 112 112c0 .2-.06.38-.06.58.11 85.1 23.31 131.46 44.53 159.42H67.53zM224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64z" />
            </svg>
            <span className="menuText">{__("Notifications")}</span>
            {props.notifictionCount > 0 && (
              <>
                <div className="notify_content">
                  <i className="fas fa-bell"></i>
                  <div className="nuber_alart_count">
                    {props.notifictionCount}
                  </div>
                </div>
              </>
            )}
            </Dropdown.Item>*/}
          <Dropdown.Item className="notify_area" href="/dashboard/messages">
            <svg height="22" width="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
              <path d="M512 160h-96V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64v160c0 35.3 28.7 64 64 64h32v52c0 7.1 5.8 12 12 12 2.4 0 4.9-.7 7.1-2.4l76.9-43.5V384c0 35.3 28.7 64 64 64h96l108.9 61.6c2.2 1.6 4.7 2.4 7.1 2.4 6.2 0 12-4.9 12-12v-52h32c35.3 0 64-28.7 64-64V224c0-35.3-28.7-64-64-64zM96 240H64c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h288c8.8 0 16 7.2 16 16v160c0 8.8-7.2 16-16 16H211.4l-11 6.2-56.4 31.9V240H96zm432 144c0 8.8-7.2 16-16 16h-80v38.1l-56.4-31.9-11-6.2H256c-8.8 0-16-7.2-16-16v-96h112c35.3 0 64-28.7 64-64v-16h96c8.8 0 16 7.2 16 16v160z" />
            </svg>
            <span className="menuText">{__("Messages")}</span>
            {props.messageCount > 0 && (
              <>
                <div className="notify_content">
                  <i className="fas fa-bell"></i>
                  <div className="nuber_alart_count">{props.messageCount}</div>
                </div>
              </>
            )}
          </Dropdown.Item>
          <Dropdown.Item className="responsible_gamings_menu" href="/dashboard/responsible-gaming">
            <img src={require("../assets/images/icons/responsible-gaming.svg").default} />
            <span className="menuText">{__("Responsible Gaming")}</span>
          </Dropdown.Item>
          <Dropdown.Item className="verification_menu small_circle" href="/dashboard/verify">
            <img src={require("../assets/images/icons/verification.svg").default} />
            <span className="menuText">{__("Verify")}</span>
            {(!level2submitted || !level3submitted) && <div className="notification_round"></div>}

            {level2submitted && level3submitted && <div className="notification_round orenge"></div>}
          </Dropdown.Item>
          <Dropdown.Item href="/dashboard/account-activity">
            <svg height="22" width="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm-6 400H54a6 6 0 0 1-6-6V86a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v340a6 6 0 0 1-6 6zm-42-92v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm0-96v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm0-96v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12zm-252 12c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36zm0 96c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36zm0 96c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36z" />
            </svg>
            <span className="menuText">{__("Account Activity")}</span>
          </Dropdown.Item>
          <Dropdown.Item className="my_game_menu" href="/dashboard/my-games">
            <img src={require("../assets/images/icons/heart-svgrepo-com.svg").default} />
            <span className="menuText">{__("My Games")}</span>
          </Dropdown.Item>
          <Dropdown.Item className="refer_and_earn_menu" href="/dashboard/refer-and-earn">
            <img src={require("../assets/images/icons/refer-and-earn.svg").default} />
            <span className="menuText">{__("Refer and Earn")}</span>
          </Dropdown.Item>
          <Dropdown.Item className="store_menu" href="/store">
            <img src={require("../assets/images/icons/store.svg").default} />
            <span className="menuText">{__("Store")}</span>
          </Dropdown.Item>
          <Dropdown.Item className="tickets_menu" href="/tickets">
            <img src={require("../assets/images/icons/tickets-icon.svg").default} alt={__("Tickets")} />
            <span className="menuText">{__("Tickets")}</span>
          </Dropdown.Item>

          <Dropdown.Item className="logoutMenu" onClick={logout}>
            <img src={require("../assets/images/icons/log-off-icon.svg").default} />
            <span className="menuText">
              {""} {__("Logout")}
            </span>
          </Dropdown.Item>
          {props.isMobileDevice && (
            <div className="button_close">
              <i
                onClick={() => {
                  setDefaultShowing(false);
                }}
                className="fa fa-times"
                aria-hidden="true"
              ></i>
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
      {defualtShowing && (
        <div
          onClick={() => {
            setDefaultShowing(false);
            props.setShowLeftSidebar(false);
          }}
          className="main_overlay"
        ></div>
      )}
    </>
  );
};

export default UserDropDownMenu;
