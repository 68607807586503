import React, { useState, useEffect, useContext } from "react";
import GamesFilter from "elements/games_elements/GamesFilter";
import CategoryGames from "elements/games_elements/CategoryGames";
import CategoryGameSlider from "elements/games_elements/CategoryGameSlider";
import api from "services/api";
import SearchBox from "elements/SearchBox";

import { useParams, useLocation, useHistory } from "react-router-dom";
import Banner from "elements/Banner";
import { __ } from "services/Translator";

export default function Games(props) {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  // const __ = (text) => text; //Placeholder for future translations function

  const gameTypeAPISlugs = {
    all: "all",
    slots: "video_slots",
    blackjack: "blackjack ",
    "video-poker": "table_games",
    "live-dealer": "live_casino",
    "live-casino": "live_casino",
    roulette: "roulette",
    baccarat: "baccarat",
    jackpot: "jackpot",
    "table-games": "table_games",
    search: "search_results",
    popular: "popular",
    new: "new",
    "crash-games": "crash_games",
    other: "other",
    "virtual-games": "virtual_games",
    "video-poker": "video_poker",
  };

  const gameTitles = {
    slots: __("Slots"),
    // blackjack: __("Blackjack"),
    blackjack: __("Table Games"),
    // "video-poker": __("Video Poker"),
    "video-poker": __("Table Games"),
    "live-casino": __("Live Casino"),
    // roulette: __("Roulette"),
    roulette: __("Table Games"),
    // baccarat: __("Baccarat"),
    baccarat: __("Table Games"),
    jackpot: __("Jackpot Games"),
    "table-games": __("Table Games"),
    all: __("All Games"),
    popular: __("Popular Games"),
    search_results: __("Search Results"),
    new: "New",
    virtual_games: "Virtual Games",
    other: "Other",
    "crash-games": "Crash Games",
  };

  const gameType = params.category ? params.category : "all";

  //Backup codes
  const [pagination, setPagination] = useState(null);
  const [games, setGames] = useState([]);
  const [gameSorting, setGameSorting] = useState("");

  const [gameLoaded, setGameLoaded] = useState(0);
  const [totalGame, setTotalGame] = useState(0);

  const getGames = async (page) => {
    console.log("gameTypegameType", gameType);
    props.setSpinner(true);
    var response = await api.post("/games/by-category", {
      per_page: 100,
      game_types: [gameTypeAPISlugs[gameType]],
      page: page,
      game_sorting: gameSorting,
      providers: props.selectedProviders,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      props.getProviders(gameTypeAPISlugs[gameType]);
      setIsSearchResultsShowing(false);
      props.setSearchStr("");
      if (page === 1) {
        setGames(response.data.games);
      } else {
        var realGameType = gameTypeAPISlugs[gameType];

        var gamesClone = JSON.parse(JSON.stringify(games));

        gamesClone[realGameType].gamelist = [
          ...gamesClone[realGameType].gamelist,
          ...response.data.games[realGameType].gamelist,
        ];
        setGames(gamesClone);
      }

      setGameLoaded(page === 1 ? response.data.loadedGameCount : gameLoaded + response.data.loadedGameCount);
      setTotalGame(response.data.gameCount);

      if (response.data.paginationData) {
        setPagination(response.data.paginationData);
      } else {
        setPagination(null);
      }
    }
  };

  const onPageChange = (page) => {
    if (gameType === "search") {
      searchGames(page, null);
    } else {
      getGames(page);
    }
  };

  useEffect(() => {
    if (gameType === "search") {
      if (props.searchStr.length) {
        searchGames(1);
      } else {
        history.push("/games/all");
      }
    } else {
      getGames(1);
    }
  }, [gameSorting, gameType, props.searchStr, props.selectedProviders]);

  const searchGames = async (page) => {
    var perPage = 30;
    var serchParams = {
      search_query: props.searchStr,
      page: page,
      per_page: perPage,
      providers: props.selectedProviders,
    };

    var response = await api.post("/games/search", serchParams);
    if (response.status === 200) {
      setIsSearchResultsShowing(true);
      setGames(response.data.games);
      if (response.data.paginationData) {
        setPagination(response.data.paginationData);
      } else {
        setPagination(null);
      }

      setGameLoaded(page === 1 ? response.data.loadedGameCount : gameLoaded + response.data.loadedGameCount);
      setTotalGame(response.data.gameCount);
    }
  };

  const [isSearchResultsShowing, setIsSearchResultsShowing] = useState(false);

  return (
    <>
      {!props.isAuthenticated && (
        <>
          <Banner {...props} />
        </>
      )}

      <div className="category_menu_and_search_area ">
        <div className="category_menu">
          <GamesFilter {...props} />
        </div>
        {/* <SearchBox {...props} /> */}
      </div>

      <CategoryGames
        {...props}
        gameList={games[gameTypeAPISlugs[gameType] ?? "all"]?.gamelist ?? []}
        categoryName={gameTitles[gameType] ?? ""}
        isSearchResults={isSearchResultsShowing}
        category={gameType}
        onPageChange={onPageChange}
        pagination={pagination}
        setGameSorting={setGameSorting}
        gameSorting={gameSorting}
        showPagination={true}
        gameLoaded={gameLoaded}
        totalGame={totalGame}
      />
    </>
  );
}
