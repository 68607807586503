import ls from "local-storage";

//Auth module
import PrivateRoute from "./PrivateRoute";
import OpenRoute from "./OpenRoute";
import ChatRoute from "routes/ChatRoute";
import NotFoundRoute from "routes/NotFoundRoute";

// import external modules
import React, { Component, lazy } from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";

import Landing from "pages/Landing";
import UserDashboard from "pages/UserDashboard";
import ContentPage from "pages/ContentPage";
import Promotions from "pages/Promotions";
import Terms from "pages/Terms";
import TermsAndconditions from "pages/TermsAndconditions";
import PrivacyPolicy from "pages/PrivacyPolicy";
import BonusTerms from "pages/BonusTerms";
import AMLPolicy from "pages/AMLPolicy";
import ResponsibleGaming from "pages/ResponsibleGaming";
import About from "pages/About";
import ProvablyFair from "pages/ProvablyFair";

import Vip from "pages/Vip";
import Games from "pages/Games";
import GamePlay from "pages/GamePlay";
import Lobby from "pages/Lobby";
import FAQ from "pages/FAQ";

import Blogs from "../pages/Blogs";
import BlogDetails from "../pages/BlogDetails";

// auth pages
import Login from "pages/auth/Login";

import RecoverPassword from "pages/auth/RecoverPassword";
import ResetPassword from "pages/auth/ResetPassword";

// //Ticket
// import TicketDetails from "pages/TicketDetails";

//Stores
import Stores from "../pages/Stores";

import Chat from "elements/Chat";
import NotFound404 from "pages/NotFound404";

//Tickets
import Tickets from "pages/Tickets";
import TicketDetails from "pages/TicketDetails";

import Rewards from "pages/Rewards";

class OpenRoutes extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <Switch>
          <OpenRoute exact path="/" component={Landing}></OpenRoute>
          <OpenRoute exact path="/account-activated" activated={true} component={Landing}></OpenRoute>
          <OpenRoute exact path="/refer/:referral_code" component={Landing}></OpenRoute>
          <OpenRoute exact path="/referral/:referral_code" component={Landing}></OpenRoute>
          <OpenRoute exact path="/signup" component={Landing}></OpenRoute>
          <OpenRoute exact path="/support" component={Landing}></OpenRoute>
          <OpenRoute exact path="/promotions" component={Promotions}></OpenRoute>
          <OpenRoute exact path="/terms-and-conditions" component={TermsAndconditions}></OpenRoute>
          <OpenRoute exact path="/privacy-policy" component={PrivacyPolicy}></OpenRoute>
          <OpenRoute exact path="/provably-fair" component={ProvablyFair}></OpenRoute>
          <OpenRoute exact path="/bonus-terms" component={BonusTerms}></OpenRoute>
          <OpenRoute exact path="/aml-policy" component={AMLPolicy}></OpenRoute>
          <OpenRoute exact path="/responsible-gaming" component={ResponsibleGaming}></OpenRoute>
          <OpenRoute exact path="/about" component={About}></OpenRoute>

          <OpenRoute exact path="/vip" component={Vip}></OpenRoute>
          <OpenRoute exact path="/faq" component={FAQ}></OpenRoute>
          <OpenRoute exact path="/games/:category" component={Games}></OpenRoute>
          <OpenRoute exact path="/play/:slug/:mode?" component={GamePlay}></OpenRoute>
          {/* auth pages */}
          <OpenRoute exact path="/login" component={Login}></OpenRoute>

          <OpenRoute exact path="/recover-password" component={RecoverPassword}></OpenRoute>
          <OpenRoute exact path="/reset-password/:userId/:code" component={ResetPassword}></OpenRoute>

          <OpenRoute exact path="/content/:page?" component={ContentPage}></OpenRoute>

          <OpenRoute exact path="/blogs" component={Blogs}></OpenRoute>
          <OpenRoute exact path="/blog-details/:slug" component={BlogDetails}></OpenRoute>

          <OpenRoute exact path="/store" component={Stores}></OpenRoute>

          <Redirect path="*" to="/" />
          {/* <NotFoundRoute exact path="*" component={NotFound404}></NotFoundRoute> */}
        </Switch>
      </BrowserRouter>
    );
  }
}

class PrivateRoutes extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <Switch>
          <PrivateRoute exact path="/" component={Landing}></PrivateRoute>
          <PrivateRoute exact path="/support" component={Landing}></PrivateRoute>
          {/* <PrivateRoute exact path="/games/live-casino" component={Lobby}></PrivateRoute> */}
          <PrivateRoute exact path="/games/:category" component={Games}></PrivateRoute>

          <PrivateRoute exact path="/play/:slug/:mode?" component={GamePlay}></PrivateRoute>
          <PrivateRoute exact path="/dashboard/:tab?/:extraParam1?" component={UserDashboard}></PrivateRoute>
          <PrivateRoute exact path="/content/:page?" component={ContentPage}></PrivateRoute>
          <PrivateRoute exact path="/promotions" component={Promotions}></PrivateRoute>
          <PrivateRoute exact path="/faq" component={FAQ}></PrivateRoute>
          <PrivateRoute exact path="/terms-and-conditions" component={TermsAndconditions}></PrivateRoute>
          <PrivateRoute exact path="/privacy-policy" component={PrivacyPolicy}></PrivateRoute>
          <PrivateRoute exact path="/provably-fair" component={ProvablyFair}></PrivateRoute>
          <PrivateRoute exact path="/bonus-terms" component={BonusTerms}></PrivateRoute>
          <PrivateRoute exact path="/aml-policy" component={AMLPolicy}></PrivateRoute>
          <PrivateRoute exact path="/responsible-gaming" component={ResponsibleGaming}></PrivateRoute>
          <PrivateRoute exact path="/about" component={About}></PrivateRoute>
          <PrivateRoute exact path="/vip" component={Vip}></PrivateRoute>
          <PrivateRoute exact path="/promotions" component={Promotions}></PrivateRoute>

          <PrivateRoute exact path="/blogs" component={Blogs}></PrivateRoute>
          <PrivateRoute exact path="/blog-details/:slug" component={BlogDetails}></PrivateRoute>

          <PrivateRoute exact path="/support/ticket/:id" component={TicketDetails}></PrivateRoute>

          <PrivateRoute exact path="/tickets" component={Tickets} />
          <PrivateRoute exact path="/ticket-details/:id" component={TicketDetails} />

          <PrivateRoute exact path="/store" component={Stores}></PrivateRoute>
          <PrivateRoute exact path="/rewards" component={Rewards} />
          <ChatRoute exact path="/chat" component={Chat}></ChatRoute>
          <Redirect path="*" to="/" />
          {/* <NotFoundRoute exact path="*" component={NotFound404}></NotFoundRoute> */}
        </Switch>
      </BrowserRouter>
    );
  }
}

export { OpenRoutes, PrivateRoutes };
