import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useLocation } from "react-router-dom";
import api from "services/api";
import auth from "services/auth";
import ls from "services/ls";
import config from "configs/config";
import InputBox from "elements/InputBox";
import { Accordion } from "react-bootstrap";

import Translator, { __ } from "services/Translator";
import MyTickets from "elements/MyTickets";

import emailValidate from "../services/emailValidate";

export default function SupportSidebar(props) {
  // const __ = (text) => text;
  const location = useLocation();
  const isAuthenticated = props.isAuthenticated;
  const userDetails = props.userDetails;
  const userBalance = props.userBalance;
  const supportSidebarOpen = props.supportSidebarOpen;
  const setSupportSidebarOpen = props.setSupportSidebarOpen;

  const [selectedLanguage, setSelectedLanguage] = useState({});

  const getSelectedLanguage = async () => {
    var lang = await Translator.getSelectedLanguage();
    setSelectedLanguage(lang);
  };

  useEffect(() => {
    getSelectedLanguage();
  }, []);

  const [form, setForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const updateForm = (value, field) => {
    if (isAuthenticated && (field === "email" || field === "name")) {
      return false;
    }
    setForm({ ...form, [field]: value });
  };

  useEffect(() => {
    if (isAuthenticated) {
      setForm({
        ...form,
        email: userDetails.email,
        name: userDetails.first_name ? userDetails.first_name + " " + userDetails.last_name : userDetails.nick_name,
      });
    }
  }, [userDetails]);

  const [launchUrl, setLaunchUrl] = useState(config.liveChatUrl);
  // const [launchUrl, setLaunchUrl] = useState("https://google.com");

  useEffect(() => {
    var launchUrlTmp = config.liveChatUrl;
    launchUrlTmp += "&cer=true";
    if (selectedLanguage) {
      launchUrlTmp += "&lang=" + selectedLanguage.code;
    }
    if (props.isAuthenticated) {
      var userDetails = props.userDetails;
      console.log(userDetails);
      launchUrlTmp +=
        "&username=" + userDetails.first_name + " " + userDetails.last_name + "&email=" + userDetails.email;
    }
    setLaunchUrl(launchUrlTmp);
  }, [selectedLanguage, props.isAuthenticated]);

  useEffect(() => {
    window.addEventListener("message", (event) => {
      console.log("EVENTMSG", event);
      if (event.origin == "https://bossoffice.zinocasino.com") {
        if (event.data == "liveChat.openTicketScreen") {
          setActiveTab("ticket");
        }
      }
    });
  }, []);

  const [faqs, setFaqs] = useState([]);

  const getFaqs = async () => {
    props.setSpinner(true);
    var response = await api.get("/get-faqs");
    props.setSpinner(false);
    if (response.status === 200) {
      setFaqs(response.data);
    }
  };
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};

    if (form.name.length < 2 || form.name.length > 250) {
      errorsTmp = { ...errorsTmp, name: __("Please enter your name") };
      valid = false;
    }

    if (!emailValidate(form.email)) {
      errorsTmp = { ...errorsTmp, email: "Please enter a valid email address" };
      valid = false;
    }

    if (form.email.length < 2 || form.email.length > 250) {
      errorsTmp = { ...errorsTmp, email: __("Please enter your email") };
      valid = false;
    }

    if (form.subject.length < 2 || form.subject.length > 250) {
      errorsTmp = { ...errorsTmp, subject: __("Please enter the subject") };
      valid = false;
    }

    if (form.message.length < 1) {
      errorsTmp = { ...errorsTmp, message: __("Please enter the message") };
      valid = false;
    }

    setErrors(errorsTmp);

    return valid;
  };

  const sendMail = async (ev) => {
    ev.preventDefault();
    var valid = validateForm();
    if (valid) {
      props.setSpinner(true);
      var response = await api.post("/create-ticket", form);
      props.setSpinner(false);
      if (response.status === 200) {
        props.showAlert("Success!!", response.data.message);
        setForm({ ...form, subject: "", message: "" });
      } else {
        props.showAlert("Warning!!", response.data.message, true);
      }
    } else {
    }
  };

  useEffect(() => {
    getFaqs();
  }, []);

  const onWebViewLoaded = (web) => {
    props.setSpinner(false);
  };
  const onWebViewLoadedIframe = () => {
    props.setSpinner(false);
  };

  const toggleSupportSidebar = () => {
    setSupportSidebarOpen(!supportSidebarOpen);
  };

  useEffect(() => {
    // setSupportSidebarOpen(false);
  }, [location.pathname]);

  const accordionToggled = (lastOpenItem) => {
    if (lastOpenItem !== null) {
      lastOpenItem = parseInt(lastOpenItem);
    }
  };

  const [activeTab, setActiveTab] = useState("faq");

  return (
    <>
      {supportSidebarOpen && <div className="invisible_overlay" onClick={toggleSupportSidebar}></div>}
      {/*<a onClick={toggleSupportSidebar} href="#" className="support_btn">
        {__("Get Support")}
      </a>*/}
      <div className={"support_panel support_panel_area " + (supportSidebarOpen ? "open" : "")} id="autoprocessed">
        <a onClick={toggleSupportSidebar} className="close_icon" href="javascript:">
          <i className="fal fa-times"></i>
        </a>

        <div className="panel_header">
          <h3>{__("Support")}</h3>
        </div>

        <div className="tabs">
          <a href="#" onClick={() => setActiveTab("faq")} className={activeTab === "faq" ? "active" : ""}>
            <span>
              <i className="m-icon-question"></i> {__("FAQ")}
            </span>
          </a>
          <a onClick={() => setActiveTab("ticket")} href="#" className={activeTab === "ticket" ? "active" : ""}>
            <span>
              <i className="m-icon-email"></i> {__("Email Us")}
            </span>
          </a>
          <a onClick={() => setActiveTab("chat")} href="#" className={activeTab === "chat" ? "active" : ""}>
            <span>
              <i className="m-icon-call"></i> {__("Live Chat")}
            </span>
          </a>
        </div>

        <div className="support_sidebar_content">
          {activeTab === "faq" && (
            <div className="access_control_area chimp-accordions">
              <Accordion onSelect={accordionToggled}>
                {faqs.map((faqCategory, index) => (
                  <Accordion.Item className="chimp-accordion" key={index} eventKey={index}>
                    <Accordion.Header className="faq-section faq__question">
                      {faqCategory.category}
                      <i></i>
                    </Accordion.Header>
                    <Accordion.Body className="faq_category_details">
                      <Accordion>
                        {faqCategory.faqs.map((faq, index2) => (
                          <Accordion.Item key={index2} eventKey={index2} className="faq-item chimp-accordion">
                            <Accordion.Header>{faq.title}</Accordion.Header>
                            <Accordion.Body className="faq-body">{faq.description}</Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          )}
          {activeTab === "ticket" && (
            <div id="support_email_us" className="email_us">
              {__("You can Live Chat with out Support Team.")}
              <br />

              {__(
                "Click on the Live Support tab when openening the support panel. Feel free to ask them any question you have regarding Zino Casino, your account or (Live) games on the website."
              )}
              <br />
              <br />

              {__("If you want to send us a message through our contact form then you can send it from here.")}

              <div className="contact_us_area">
                <div className="row">
                  <div className="reg_field col-sm-6">
                    <InputBox
                      type="text"
                      className="form-control required_field"
                      readOnly={isAuthenticated}
                      required={true}
                      value={form.name}
                      onChange={(value) => updateForm(value, "name")}
                      placeholder={__("Full Name")}
                    />
                    <div className="errorMsg">{errors.name}</div>
                  </div>
                  <div className="reg_field col-sm-6">
                    <InputBox
                      type="email"
                      className="form-control required_field"
                      readOnly={isAuthenticated}
                      required={true}
                      value={form.email}
                      onChange={(value) => updateForm(value, "email")}
                      placeholder={__("Email")}
                    />
                    <div className="errorMsg">{errors.email}</div>
                  </div>
                </div>

                <div className="row">
                  <div className="reg_field col-sm-12">
                    <InputBox
                      required
                      type="text"
                      className="form-control required_field"
                      value={form.subject}
                      onChange={(value) => updateForm(value, "subject")}
                      placeholder={__("Subject")}
                    />
                    <div className="errorMsg">{errors.subject}</div>
                  </div>
                </div>

                <div className="reg_field">
                  <textarea
                    required
                    name="message"
                    className="form-control required_field"
                    rows="10"
                    value={form.message}
                    onChange={(ev) => updateForm(ev.target.value, "message")}
                    placeholder={__("Message")}
                  ></textarea>
                  <div className="errorMsg">{errors.message}</div>
                </div>
                <input type="hidden" name="g-recaptcha-response" value="" />
                <div className="reg-submit">
                  <button onClick={sendMail} className="common_btn recap_submit_button">
                    {__("Submit")}
                  </button>
                </div>
                {/* {props.isAuthenticated && <MyTickets refreshTicketTriger={refreshTicketTriger} {...props} />} */}
              </div>
            </div>
          )}
          {activeTab === "chat" && <iframe className="support_chat" src={launchUrl} onLoad={onWebViewLoadedIframe} />}
        </div>

        {/*<div onClick={toggleSupportSidebar} className="collapse-support">
          {__("Collapse")}
        </div>*/}
      </div>
    </>
  );
}
