import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "services/auth";
import ls from "local-storage";
import AppContext from "contexts/AppContext";
import api from "services/api";
import emailValidate from "services/emailValidate";
import GameItem from "elements/games_elements/GameItem";
import { __ } from "services/Translator";
import JackpotTicker from "elements/JackpotTicker";

const SingleCategoryGames = (props) => {
  // const menuOpen = props.menuOpen;
  const [categoryName, setCategoryName] = useState(props.categoryName);
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [gameListsData, setGameListsData] = useState([]);
  const [popularSlots, setPopularSlots] = useState([]);
  const [tableGames, setTableGames] = useState([]);
  const category = props.category;
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(props.perPage);

  const getGames = async () => {
    var response = await api.post("/games/by-category", {
      per_page: perPage,
      page: page,
      game_types: [category],
      specific_games: props.specificGames,
    });

    if (response.status === 200 && response.data) {
      if (category == "video_slots") {
        const tempGameList = gameListsData;
        var mergedGameList = tempGameList.concat(response.data.games.video_slots.gamelist);
        setGameListsData(mergedGameList);
      } else if (category == "table_games") {
        const tempGameList = gameListsData;
        var mergedGameList = tempGameList.concat(response.data.games.table_games.gamelist);
        setGameListsData(mergedGameList);
      } else if (category == "video_poker") {
        const tempGameList = gameListsData;
        var mergedGameList = tempGameList.concat(response.data.games.video_poker.gamelist);
        setGameListsData(mergedGameList);
      } else if (category == "live_casino") {
        const tempGameList = gameListsData;
        var mergedGameList = tempGameList.concat(response.data.games.live_casino.gamelist);
        setGameListsData(mergedGameList);
      } else if (category == "new") {
        const tempGameList = gameListsData;
        var mergedGameList = tempGameList.concat(response.data.games.new.gamelist);

        setGameListsData(mergedGameList);
      } else if (category == "popular") {
        const tempGameList = gameListsData;
        var mergedGameList = tempGameList.concat(response.data.games.popular.gamelist);
        setGameListsData(mergedGameList);
      } else if (category == "jackpot") {
        const tempGameList = gameListsData;
        var mergedGameList = tempGameList.concat(response.data.games.jackpot.gamelist);
        setGameListsData(mergedGameList);
      }
    }
  };

  useEffect(async () => {
    if (category) {
      getGames();
    }
  }, [category, page]);

  const [showSidebar, setShowSidebar] = useState(false);

  const openSidebar = () => {
    setShowSidebar(true);
  };
  const closeSidebar = () => {
    setShowSidebar(false);
  };

  const loadMoreGamesAction = (prm) => {
    setPage(page + 1);
    setPerPage(24);
  };

  const gameTypeAPISlugsReverse = {
    new: "new",
    video_slots: "slots",
    live_casino: "live-casino",
    table_games: "table-games",
    popular: "popular",
    jackpot: "jackpot",
  };

  return (
    <>
      <br />
      <div className="category_games_header">
        <div className="headline_left">
          <h4 className="game_headline">{props.categoryName}</h4>
        </div>
      </div>

      <div className="game_list">
        {gameListsData.map((game, index) => (
          <GameItem
            game={game}
            key={index}
            isAuthenticated={isAuthenticated}
            setModalLoginPropsData={props.setModalLoginPropsData}
          />
        ))}
        <div className="view_more">
          <Link to={"/games/" + gameTypeAPISlugsReverse[category]}>
            <img className="arrow_right_icon" src={require("../../assets/images/icons/arrow-right.svg").default} />
            <div className="view_more_text">View more</div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default SingleCategoryGames;
