import React, { useState, useEffect, useRef } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Button, Modal } from "react-bootstrap";
import api from "services/api";
import RemainingTime from "elements/RemainingTime";
import WheelOfFortune from "elements/WheelOfFortune";

import Translator, { __ } from "services/Translator";

export default function Rewards(props) {
  const isAuthenticated = props.isAuthenticated;
  const userDetails = props.userDetails;
  const userBalance = props.userBalance;
  const setSpinner = props.setSpinner;
  const now = 60;
  const sliderRef = useRef();

  const nextLoad = () => {
    // flkty.current.next();
    sliderRef.current.slickNext();
  };

  const prevLoad = () => {
    // flkty.current.previous();
    sliderRef.current.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: false,
    autoplaySpeed: 10000,
    arrows: false,
    // className: "center",
    centerMode: false,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,

        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          className: "center",
          centerMode: true,
        },
      },
    ],
  };

  const [fortuneWheel, setFortuneWheel] = useState({});

  const getWheelStatus = async () => {
    var response = await api.get("/rewards/fortune-wheel/status");
    if (response.status == 200) {
      setFortuneWheel(response.data);
    } else {
      setFortuneWheel({});
      alert(response.data.message);
    }
  };

  useEffect(() => {
    getWheelStatus();
  }, []);

  const [showWheel, setShowWheel] = useState(false);

  const openWheelPopup = () => {
    getWheelStatus();
    setShowWheel(true);
  };

  return (
    <>
      <div className="reward_area">
        <div className="reward_items">
          <div className="single_reward_item">
            <img alt="" src={require("../assets/images/rewards/bronze-wheel-icon.png").default} />
            <h3 className="reward_item_title">{__("Spin the wheel")}</h3>
            <p className="reward_item_sub_title">
              {__("Take your chances and spin the wheel of fortune to gain unlimited prizes")}
            </p>
            {!!fortuneWheel?.id && (
              <Link className="commonBtn filledBtn" onClick={openWheelPopup}>
                {__("Spin Now")}
              </Link>
            )}
          </div>
        </div>

        <Modal show={showWheel} dialogClassName="wheel_modal" onHide={() => setShowWheel(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <section className="lucky_wheel">
              <WheelOfFortune pendingWheelStatus={false} wheel={fortuneWheel} {...props} />
            </section>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
