import { Accordion, Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SelectBox from "elements/SelectBox";
import InputBox from "elements/InputBox";
import Pagination from "elements/Pagination";
import DateSelector from "elements/DateSelector";
import NoRecordsFound from "elements/NoRecordsFound";
import api from "services/api";
import { __ } from "services/Translator";

export default function TransactionHistory(props) {
  const defaultForm = {
    historyFor: "Deposits",
    startDate: null,
    endDate: null,
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    props.setSpinner(true);
    var response = await api.post("/history/transactions", form);
    props.setSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const doReset = () => {
    setForm(defaultForm);
  };

  const [exportModal, setExportModal] = useState(false);
  const toggleExportModal = () => {
    setExportModal(!exportModal);
  };

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  const depositOrWithdrawal = [
    { label: __("Deposits"), value: "Deposits" },
    { label: __("Withdrawals"), value: "Withdrawals" },
    { label: __("All"), value: "All" },
  ];

  const d = new Date();
  let year = d.getFullYear();

  //Cancel Withdrawals
  const [cancelWithdrawalModal, setCancelWithdrawalModal] = useState(false);
  const [cancelWithdrawalId, setCancelWithdrawalId] = useState(null);

  const cancelWithdrawal = async (id) => {
    props.setSpinner(true);
    var response = await api.post("/withdraw/cancel", { id });
    props.setSpinner(false);
    if (response.status === 200) {
      getResults();
    } else {
      props.showAlert(null, response.data.message, true);
    }
  };

  return (
    <>
      <div className="dashboard_content history_area">
        <div className="left_area">
          <h3 className="tabName">{__("Transaction History")}</h3>
          <br />
          <div className="row">
            <div className="col-12">
              <div className="input_label">
                <i className="far fa-folder-open"></i> {__("Transaction For")}
              </div>
              <SelectBox
                className="form-controll input_box select_box"
                value={form.historyFor}
                onChange={(itemValue) => updateForm(itemValue, "historyFor")}
                items={depositOrWithdrawal}
              />
            </div>
          </div>
          <div className="row">
            <div className="input_label">
              <i className="far fa-calendar-alt"></i> {__("Start Day")}
            </div>
            <DateSelector
              className="filter_group"
              value={form.startDate}
              startYear={2022}
              endYear={year}
              onChange={(itemValue) => updateForm(itemValue, "startDate")}
            />
          </div>
          <div className="row">
            <div className="input_label">
              <i className="far fa-calendar-alt"></i> {__("End Day")}
            </div>
            <DateSelector
              className="filter_group"
              value={form.endDate}
              startYear={2022}
              endYear={year}
              onChange={(itemValue) => updateForm(itemValue, "endDate")}
            />
          </div>
          <div className="button_group">
            <Link
              to="#"
              onClick={getResults}
              className="common_btn uppercase no_margin"
            >
              {__("Search")}
            </Link>
            <Link
              to="#"
              onClick={doReset}
              className="common_btn uppercase borderd no_margin"
            >
              {__("Reset")}
            </Link>
          </div>
        </div>

        <div className="right_area table_area">
          {/* desktop */}
          <div className="d-none d-sm-block">
            <div className="heading_row transaction_history">
              <div className="item">{__("Type")}</div>
              <div className="item">{__("Date")}</div>
              <div className="item">{__("Amount")}</div>
              <div className="item">{__("Status")}</div>
              <div className="item">{__("Method")}</div>
              <div className="item">{__("Action")}</div>
            </div>

            {results.length ? (
              <>
                {results.map((item, index) => (
                  <div key={index} className="data_row transaction_history">
                    <div className="item">
                      <span
                        className={
                          "action_icon " +
                          (item.status_code != 1 ? "error_icon" : "")
                        }
                      >
                        {item.status_code == 1 && (
                          <i className="far fa-check"></i>
                        )}
                        {item.status_code != 1 && (
                          <i className="far fa-times"></i>
                        )}
                      </span>

                      {item.type == "Deposit"
                        ? __("Deposit")
                        : __("Withdrawal")}
                    </div>
                    <div className="item">{item.action_date}</div>
                    <div className="item">
                      <b>{item.amount}</b>
                    </div>
                    <div className="item">
                      <b>{item.status}</b>
                    </div>
                    <div className="item">
                      <span
                      // className={
                      //   "table_btn radius_5 " +
                      //   (item.status === "Success" ? "success" : "error")
                      // }
                      >
                        {item.method === "BzPay"
                          ? __("Credit Card")
                          : item.method}
                      </span>
                    </div>
                    <div className="item">
                      {item.type === "Withdrawal" && item.status_code === 0 && (
                        <a
                          onClick={() => {
                            // cancelWithdrawal(item.id);
                            setCancelWithdrawalId(item.id);
                            setCancelWithdrawalModal(true);
                          }}
                          className=" common_btn error radius_5"
                          
                        >
                          {__("Cancel")}
                        </a>
                      )}
                    </div>
                  </div>
                ))}

                <div className="pagination">
                  <Pagination data={pagination} onPageChange={onPageChange} />
                </div>
              </>
            ) : (
              <NoRecordsFound />
            )}
          </div>
          {/* mobile */}
          <div className="mobile_accordion d-block d-sm-none">
            {results.length ? (
              <>
                <Accordion>
                  {results.map((item, index) => (
                    <Accordion.Item
                      className={
                        item.status === "Success" ? "success" : "error"
                      }
                      eventKey={index}
                      key={index}
                    >
                      <Accordion.Header>{item.type}</Accordion.Header>
                      <Accordion.Body>
                        <div className="accordion_content">
                          <div className="item">
                            <div className="input_label">{__("Type")}</div>
                            <div className="input_box bg_grey">{item.type}</div>
                          </div>
                          <div className="item">
                            <div className="input_label">{__("Date")}</div>
                            <div className="input_box bg_grey">
                              {item.action_date}
                            </div>
                          </div>
                          <div className="item">
                            <div className="input_label">{__("Amount")}</div>
                            <div className="input_box bg_grey">
                              {item.amount}
                            </div>
                          </div>
                          <div className="item">
                            <div className="input_label">{__("Status")}</div>
                            <div className="input_box bg_grey">
                              {item.status}
                            </div>
                          </div>
                          <div className="item">
                            <div className="input_label">{__("Method")}</div>
                            <div
                              className={
                                "input_box bg_grey " +
                                (item.status === "Success"
                                  ? "success"
                                  : "error")
                              }
                            >
                              {item.method === "BzPay"
                                ? __("Credit Card")
                                : item.method}
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>

                <br />
                <br />
                <div className="pagination">
                  <Pagination data={pagination} onPageChange={onPageChange} />
                </div>
              </>
            ) : (
              <NoRecordsFound />
            )}
          </div>
          <br />
          <br />
        </div>
      </div>

      <Modal
        show={cancelWithdrawalModal}
        id="popup-withdraw"
        className="modal cashierModal"
        onHide={() => {
          setCancelWithdrawalModal(false);
          setCancelWithdrawalId(null);
        }}
      >
        <Modal.Header>
          <h5 className="modal-title">
            {__("Are you sure to cancel the withdrawal request?")}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setCancelWithdrawalModal(false);
              setCancelWithdrawalId(null);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="button_group">
            <button
              type="button"
              className="common_btn uppercase no_margin"
              onClick={() => {
                setCancelWithdrawalModal(false);
                setCancelWithdrawalId(null);
              }}
            >
              {__("Close")}
            </button>

            <button
              type="button"
              className="common_btn uppercase no_margin ConfirmBtn"
              onClick={cancelWithdrawal}
            >
              {__("confirm")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
