import { Accordion } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SelectBox from "elements/SelectBox";
import InputBox from "elements/InputBox";
import Pagination from "elements/Pagination";
import DateSelector from "elements/DateSelector";
import NoRecordsFound from "elements/NoRecordsFound";
import DOMPurify from "dompurify";
import api from "services/api";
import { __ } from "services/Translator";
import { Button, Modal } from "react-bootstrap";

export default function Notifications(props) {
  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    props.setSpinner(true);
    var response = await api.post("/player/notifications", form);
    props.setSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  const [fullNotification, setFullNotification] = useState(null);
  const showItem = async (message) => {
    setFullNotification(message);
    // var response = await api.post("/player/message/mark-read", {
    //   id: message.id,
    // });
    // if (response.status !== 200) {
    //   props.showAlert(null, response.data.message, true);
    // }
    // getResults();
  };

  const deleteItem = async (itemId) => {
    if (window.confirm("Confirm deletion?")) {
      var response = await api.post("/player/notification/delete", {
        id: itemId,
      });
      props.showAlert(null, response.data.message, response.status !== 200);
      getResults();
    }
  };

  return (
    <>
      <div className="dashboard_content history_area_full">
        <div className="right_area table_area p-3">
          <div className="left_area">
            <h3 className="tabName">{__("Notifications")}</h3>
          </div>
          <div className="d-block player_message_container">
            {results.length ? (
              <>
                {results.map((item, index) => (
                  <ul className="notifications-list">
                    {results.map((val, index) => (
                      <li className="notification-item white" key={index}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(val.notification_str),
                          }}
                        ></p>
                        <Link
                          to="#"
                          className="open-modal read_more_btn white"
                          onClick={() => showItem(val)}
                        >
                          {__("Read More")}
                        </Link>
                        <div className="notification-time">
                          {val.notification_time}
                        </div>

                        <a
                          href="#"
                          className="delete_notification"
                          onClick={() => deleteItem(val.id)}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </a>
                      </li>
                    ))}
                  </ul>
                ))}

                <div className="pagination">
                  <Pagination data={pagination} onPageChange={onPageChange} />
                </div>
              </>
            ) : (
              <NoRecordsFound />
            )}
          </div>

          <br />
        </div>
      </div>
      {fullNotification && (
        <Modal
          className="modal"
          show={fullNotification}
          size="lg"
          onHide={() => setFullNotification(null)}
        >
          <Modal.Body className="player_message_modal">
            <div onClick={() => setFullNotification(null)} className="modal_close">
              <i className="fal fa-times"></i>
            </div>

            <div className="modal_headline">{__("Notification")}</div>
            <div className="message-from">
              <b>{__("Date")}: </b>
              {fullNotification.notification_time} 
            </div>
            {/* <strong>
              {__("Subject")}:{fullNotification.message_subject}
            </strong> */}
            <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(fullNotification.notification_str),
              }}
            ></p>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
